import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { volunteerEnum } from "@shared/types/volunteerType";
import {
  RecruitmentCommunityUserType,
  RecruitmentOrigin,
  RecruitmentTargetCommunity,
} from "@shared/types/recruitment";
import { BERecruitment } from "@shared/types/boardEducation/recruitment";

export const RecruitmentFormValueSchema = (createdAt?: string) =>
  z
    .object({
      uploadImage: z.union([
        z.string().nullable(),
        z
          .custom<FileList>()
          .optional()
          .refine(
            (file) =>
              !file ||
              file.length === 0 ||
              (file.length > 0 &&
                ["image/jpeg", "image/png"].includes(file[0].type)),
            {
              message: "jpgもしくはpngのみ可能です",
            }
          )
          .nullable(),
      ]),
      title: z.string().min(1, "入力必須項目です"),
      isPaidVolunteer: z.boolean({ required_error: "入力必須項目です" }),
      volunteerType: z.enum(volunteerEnum, {
        errorMap: () => ({ message: "入力必須項目です" }),
      }),
      volunteerDetailType: z.string().min(1, "入力必須項目です"),
      volunteerCount: z
        .number({
          errorMap: () => ({ message: "入力必須項目です" }),
        })
        .optional()
        .refine((value) => value === undefined || value >= 1, {
          message: "1人以上を指定してください",
        })
        .refine((value) => value === undefined || value <= 9999, {
          message:
            "規定の人数を上回っています。10,000人以上を設定する場合は無制限にチェックを入れてください。",
        }),
      isUnlimitedVolunteerCount: z.boolean(),
      description: z
        .string()
        .max(10000, "10000字以内で入力してください")
        .min(1, "入力必須項目です(画像のみの投稿は不可)"),
      schedule: z
        .array(
          z
            .object({
              date: z
                .string()
                .min(1, "日付は入力必須項目です")
                .refine(
                  (dateString) => {
                    const date = new Date(dateString);
                    const now = new Date();
                    const minDate = new Date(createdAt ?? now);
                    minDate.setHours(0, 0, 0, 0);
                    return date > minDate;
                  },
                  {
                    message: "過去の日付は選択できません",
                  }
                ),
              startTime: z
                .string()
                .regex(
                  /^([01]\d|2[0-3]):?([0-5]\d)$/,
                  "開始時間は入力必須項目です"
                )
                .min(1, "開始時間は入力必須項目です"),
              endTime: z
                .string()
                .regex(
                  /^([01]\d|2[0-3]):?([0-5]\d)$/,
                  "終了時間は入力必須項目です"
                )
                .min(1, "終了時間は入力必須項目です"),
            })
            .refine(
              (data) => {
                const currentDate = new Date();
                const inputDate = new Date(data.date);
                inputDate.setHours(0, 0, 0, 0);

                if (currentDate.toDateString() === inputDate.toDateString()) {
                  const currentTime = currentDate.getTime();
                  const [hours, minutes] = data.startTime
                    .split(":")
                    .map(Number);
                  const startTime = new Date(data.date);
                  startTime.setHours(hours, minutes, 0, 0);

                  return startTime.getTime() >= currentTime;
                }

                return true;
              },
              {
                message: "過去の時間は選択できません",
                path: ["startTime"],
              }
            )
            .refine(
              (data) => {
                const { startTime, endTime } = data;
                const [startHours, startMinutes] = startTime
                  .split(":")
                  .map(Number);
                const [endHours, endMinutes] = endTime.split(":").map(Number);

                const startDate = new Date();
                startDate.setHours(startHours, startMinutes, 0, 0);

                const endDate = new Date();
                endDate.setHours(endHours, endMinutes, 0, 0);

                return endDate >= startDate;
              },
              {
                message: "終了時間は開始時間よりも後にしてください",
                path: ["endTime"],
              }
            )
        )
        .nonempty("入力必須項目です"),
      selectedLocationCommunityId: z.string().optional(),
      postalCode: z
        .string()
        .min(1, "入力必須項目です")
        .regex(
          new RegExp("^[0-9]{7}"),
          "ハイフンは入力せず、半角文字のみで入力してください"
        ),
      prefecture: z.string().min(1, "入力必須項目です"),
      city: z.string().min(1, "入力必須項目です"),
      address1: z
        .string()
        .min(1, "入力必須項目です")
        .refine(
          (value) => {
            return /[0-9０-９〇一二三四五六七八九十]+/.test(value);
          },
          {
            message: "住所を最後まで入力してください",
          }
        ),
      address2: z.string().nullable(),
      latitude: z.number().nullable(),
      longitude: z.number().nullable(),
      wageType: z.enum(["HOURLY", "DAILY", "MONTHLY"]).nullable(),
      wageAmount: z
        .number({ errorMap: () => ({ message: "入力必須項目です" }) })
        .nullable(),
      treatment: z.string().nullable(),
      teacherLicenses: z.string().array(),
      medicalLicenses: z.string().array(),
      skills: z.string().array(),
      recruitmentCommunityUserTypes: z
        .record(
          z.string(), // communityIdが入る
          z.object({
            parent: z.boolean(),
            teacher: z.boolean(),
            insideResident: z.boolean(),
            outsideResident: z.boolean(),
            admin: z.boolean(), // 教育委員会は管理者にも募集を出せる
          })
        )
        .refine(
          (values) =>
            Object.values(values).some((value) =>
              Object.values(value).some((v) => v === true)
            ),
          {
            message: "募集カテゴリーを選択してください",
          }
        )
        .transform((values) => {
          const transform: { [communityId: string]: (typeof values)[string] } =
            {};
          Object.keys(values).map((key) => {
            if (Object.values(values[key]).every((v) => v === false)) return;
            transform[key] = values[key];
          });
          return transform;
        }),
      saveTemplate: z.boolean(),
      pdfFiles: z.array(z.custom<File>()).optional(),
      alreadyUploadedPdfFiles: z.array(
        z.object({
          name: z.string(),
          path: z.string(),
        })
      ),
    })
    .refine(
      (args) => {
        const { isPaidVolunteer, wageType } = args;
        return !isPaidVolunteer || wageType !== undefined;
      },
      {
        message: "入力必須項目です",
        path: ["wageType"],
      }
    )
    .refine(
      (args) => {
        const { isPaidVolunteer, wageAmount } = args;
        return !isPaidVolunteer || wageAmount !== undefined;
      },
      {
        message: "入力必須項目です",
        path: ["wageAmount"],
      }
    );

export type RecruitmentFormValue = z.infer<
  ReturnType<typeof RecruitmentFormValueSchema>
>;

export const useRecruitmentForm = ({
  defaultValues,
  createdAt,
}: {
  defaultValues?: Partial<RecruitmentFormValue>;
  createdAt?: string;
}) => {
  const {
    control,
    register,
    getValues,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors, isSubmitted },
    watch,
    setError,
    clearErrors,
  } = useForm<RecruitmentFormValue>({
    mode: "onChange",
    defaultValues,
    resolver: zodResolver(RecruitmentFormValueSchema(createdAt)),
  });

  return {
    control,
    register,
    getValues,
    setValue,
    trigger,
    isSubmitted,
    handleSubmit,
    errors,
    watch,
    setError,
    clearErrors,
  };
};

export const getImageUrl = (
  uploadImage?: string | FileList | null | undefined
): string | null => {
  if (typeof uploadImage === "string") {
    return uploadImage;
  } else if (uploadImage?.[0] === undefined) {
    return null;
  } else if (uploadImage) {
    return URL.createObjectURL(uploadImage[0]);
  } else {
    return null;
  }
};

export const covertFormValueToRecruitment = (
  data: RecruitmentFormValue
): BERecruitment => {
  return {
    id: "",
    communityId: "",
    organizationId: "",
    status: "OPENED",
    title: data.title,
    description: data.description,
    volunteerType: data.volunteerType,
    volunteerDetailType: data.volunteerDetailType,
    // isUnlimitedVolunteerCountの指定がある時はvolunteerCountを無制限の「-1」
    // 型の都合上、volunteerCountがundefinedの場合は0にする、zodのバリデーション上は発生しない想定
    volunteerCount: data.isUnlimitedVolunteerCount
      ? -1
      : data.volunteerCount ?? 0,
    isPaidVolunteer: data.isPaidVolunteer,
    wageType: data.wageType,
    wageAmount: data.wageAmount,
    treatment: data.treatment,
    teacherLicenses: data.teacherLicenses,
    medicalLicenses: data.medicalLicenses,
    skills: data.skills,
    targetCommunities: convertRecruitmentCommunityUserTypes(
      data.recruitmentCommunityUserTypes
    ),
    postalCode: data.postalCode,
    prefecture: data.prefecture,
    city: data.city,
    address1: data.address1,
    address2: data.address2,
    schedule: [
      ...data.schedule.map((schedule) => ({
        date: new Date(schedule.date),
        start: new Date(`${schedule.date}T${schedule.startTime}`),
        end: new Date(`${schedule.date}T${schedule.endTime}`),
      })),
    ],
    picture: getImageUrl(data.uploadImage),
    pictureUploadPath: "",
    latitude: data.latitude,
    longitude: data.longitude,
    walkMinute: null,
    bikeMinute: null,
    carMinute: null,
    saveTemplate: false,
    origin: RecruitmentOrigin.BOARD_EDUCATION,
  };
};

export const convertRecruitmentCommunityUserTypes = (
  recruitmentCategories: RecruitmentFormValue["recruitmentCommunityUserTypes"]
): RecruitmentTargetCommunity[] => {
  const targetCommunities = Object.keys(recruitmentCategories)
    .map((key) => {
      const recruitmentCategory = recruitmentCategories[key];

      if (
        Object.values(recruitmentCategory).every(
          (category) => category === false
        )
      )
        return;
      const communityUserTypes: RecruitmentCommunityUserType[] = [];
      if (recruitmentCategory.parent)
        communityUserTypes.push(RecruitmentCommunityUserType.PARENT);
      if (recruitmentCategory.teacher)
        communityUserTypes.push(RecruitmentCommunityUserType.TEACHER);
      if (recruitmentCategory.insideResident)
        communityUserTypes.push(RecruitmentCommunityUserType.INSIDE_RESIDENT);
      if (recruitmentCategory.outsideResident)
        communityUserTypes.push(RecruitmentCommunityUserType.OUTSIDE_RESIDENT);
      if (recruitmentCategory.admin)
        communityUserTypes.push(RecruitmentCommunityUserType.ADMIN);
      return {
        communityId: key,
        communityUserTypes,
      };
    })
    .filter((value): value is RecruitmentTargetCommunity => !!value);

  return targetCommunities;
};
