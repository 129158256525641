import React, { useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import { createChatMessage } from "../../../../apiClients/chatMessage";
import { updateParticipantChatRoom } from "../../../../apiClients/participantChatRoom";
import { useMessageList } from "../useMessageList";
import { ChatMessage } from "src/features/common/ChatMessage/components/ChatMessage";
import { DateLabel } from "src/features/common/ChatMessage/components/DateLabel";

import {
  ApprovalStatus,
  ChatRoom,
  GroupChatWithOrganization,
} from "@shared/types/chatRoom";
import { GroupContentChatMessageInputArea } from "./GroupContentChatMessageInputArea";
import { SystemMessage } from "src/features/common/ChatMessage/components//SystemMessage";
import { useToast } from "src/components/Toast";
import { APIError } from "src/utils/types/ApiError";
import { MenuModal } from "./MenuModal";
import { isCsAdminRole } from "src/utils/types/role";
import { useCurrentUser } from "src/hooks/recoil/user";
import { BREAKPOINTS, Col } from "src/components/Responsive";
import { ChatRoomBackButton } from "src/features/common/ChatMessage/components/ChatRoomBackButton";
import { useFloatingDateLabel } from "../../../common/ChatMessage/hooks/useFloatingDateLabel";
import { useGetCommunityById } from "src/hooks/query/communityById";
import { ChatMessages } from "src/features/common/ChatMessage/components/ChatMessages";

type Props = {
  isOpen: boolean;
  chatRoomParticipantId: string;
  chatRoom: ChatRoom;
  groupChatWithOrganization: GroupChatWithOrganization;
  lastReadAt: Date;
  lastReadAts: Date[];
  refetch: () => void;
};

export const ChatRoomGroupContent = (props: Props) => {
  const chatRoomInfoRef = useRef<HTMLDivElement>(null);
  const [input, setInput] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [sending, setSending] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const messageListRef = useRef<HTMLDivElement>(null); // チャットメッセージエリアの参照
  const toast = useToast();
  const user = useCurrentUser();
  const isAdmin = props.chatRoomParticipantId !== user.id;
  const { community } = useGetCommunityById(props.chatRoom.communityId ?? "");
  const {
    displayItemList,
    NewMessageNotification,
    lastReadAts,
    fetchChatMessages,
  } = useMessageList({
    initLastReadAt: props.lastReadAt,
    initLastReadAts: props.lastReadAts,
    messageListRef,
    chatRoomParticipantId: props.chatRoomParticipantId,
  });
  const currentDate = useFloatingDateLabel({
    messageListRef,
    displayItemList,
  }); // メッセージ一覧画面の上部にfixedで表示する日付

  // チャットルームの最終アクセス日時を更新
  useEffect(() => {
    // 参加してないチャットルームに関しては既読処理をしない
    if (
      props.chatRoom.ParticipantChatRooms.find(
        (participantChatRoom) =>
          participantChatRoom.participantId === props.chatRoomParticipantId
      )?.approvalStatus !== ApprovalStatus.APPROVED
    ) {
      return;
    }
    const updateParticipantChatRoomHandler = () => {
      updateParticipantChatRoom({
        chatRoomId: props.chatRoom.id,
      });
    };

    updateParticipantChatRoomHandler();

    const interval = setInterval(updateParticipantChatRoomHandler, 10000);

    return () => {
      clearInterval(interval);
      updateParticipantChatRoomHandler();
    };
  }, [props.chatRoom]);

  // ChatRoomInfoの高さ分paddingをつける
  const chatRoomInfoHeight = useMemo(() => {
    return chatRoomInfoRef.current?.clientHeight ?? 0;
  }, [chatRoomInfoRef.current]);

  const handleApprove = async () => {
    fetchChatMessages();
    props.refetch();
  };

  const onSend = async () => {
    if (!canSend) return;
    setSending(true);
    try {
      await createChatMessage(props.chatRoom.id, input, files);
      setInput("");
    } catch (e) {
      if (e instanceof APIError) {
        toast.error(e.message);
        return;
      }
      toast.error("メッセージの送信に失敗しました");
    } finally {
      setSending(false);
    }
  };

  const canSend = useMemo(() => {
    return !sending && (input.length > 0 || files.length > 0);
  }, [sending, input, files]);

  const isCSAdmin = isCsAdminRole(user.role);
  const participantChatRoom = props.chatRoom.ParticipantChatRooms.find(
    (participantChatRoom) =>
      participantChatRoom.participantId === props.chatRoomParticipantId
  );

  if (!displayItemList) return <></>;
  if (
    props.chatRoom.approvalStatus === ApprovalStatus.REJECTED ||
    participantChatRoom?.approvalStatus === ApprovalStatus.REJECTED
  )
    return <></>;

  return (
    <ChatRoomContainer isOpen={props.isOpen}>
      <ChatRoomInfo ref={chatRoomInfoRef}>
        <Header>
          <ChatRoomBackButton targetRoute="RESIDENT_CHAT" />
          <Col>
            <HeaderTitle>{props.chatRoom.name}</HeaderTitle>
            {!isAdmin && <SubTitle>{community?.name}</SubTitle>}
          </Col>

          {participantChatRoom?.approvalStatus === ApprovalStatus.APPROVED && (
            <>
              <MenuButton
                type="button"
                onClick={() => {
                  setIsOpenMenu(true);
                }}
              >
                ...
              </MenuButton>
              <MenuModal
                isOpen={isOpenMenu}
                isAdmin={isCSAdmin}
                onClose={() => {
                  setIsOpenMenu(false);
                }}
                chatRoom={props.chatRoom}
                refetch={props.refetch}
              />
            </>
          )}
        </Header>
      </ChatRoomInfo>
      <ChatBox paddingTop={chatRoomInfoHeight}>
        <ChatMessages messageListRef={messageListRef}>
          {currentDate && <FloatDateLabel>{currentDate}</FloatDateLabel>}
          {displayItemList.map((item, index) => {
            switch (item.itemType) {
              case "dateLabel":
                return (
                  <DateLabel key={index} data={item} className="date-label" />
                );
              case "systemMessage":
                return <SystemMessage key={index} message={item} />;
              case "userMessage":
                return (
                  <ChatMessage
                    key={index}
                    message={item}
                    participantId={props.chatRoomParticipantId}
                    lastReadAts={lastReadAts}
                    isGroupChat={true}
                  />
                );
            }
          })}
        </ChatMessages>
        <NewMessageNotification />
        <GroupContentChatMessageInputArea
          input={input}
          setInput={setInput}
          setFiles={setFiles}
          canSend={canSend}
          onSend={onSend}
          onApprove={handleApprove}
          chatRoom={props.chatRoom}
          chatRoomParticipantId={props.chatRoomParticipantId}
          groupChatWithOrganization={props.groupChatWithOrganization}
          isAdmin={isAdmin}
        />
      </ChatBox>
    </ChatRoomContainer>
  );
};

const ChatRoomContainer = styled.div<{ isOpen: boolean }>`
  position: relative;
  flex: 1;
  min-width: 0;
  height: 100%;
  max-height: 100%;
  background: #fff;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }
`;

const ChatRoomInfo = styled.div`
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChatBox = styled.div<{ paddingTop: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${(props) =>
    props.paddingTop ? props.paddingTop + "px 8px 0 8px" : "60px 8px 0 8px"};
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  gap: 8px;
  & > button:first-child {
    display: none;
    @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
      display: block;
    }
  }
`;

const HeaderTitle = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.05em;
  text-align: left;
`;

const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #69707d;
`;

const FloatDateLabel = styled.div`
  background: white;
  height: 24px;
  font-size: 12px;
  padding: 0 16px;
  border-radius: 24px;
  border: 1px solid rgba(221, 221, 221, 1);
  text-align: center;
  width: fit-content;
  font-weight: 600;
  position: sticky;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  line-height: 22px;
`;

const MenuButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: #e1e2e5;
  color: #343741;
  border-radius: 6px;
  border: none;
  margin-left: auto;
  padding-bottom: 10px;
  cursor: pointer;
`;
