import React from "react";
import {
  ModalBody,
  ModalHeader,
  ModalPortal,
  ModalSubmitButtons,
} from "../../../components/Modal";

interface Props {
  onClose: () => void;
}

export default function SelfDeclineApplicationForbiddenModal({
  onClose,
}: Props) {
  return (
    <>
      <ModalPortal onClose={onClose}>
        <ModalHeader>参加を不参加</ModalHeader>
        <ModalBody>
          <div>
            当日のため、不参加にできません。管理者に直接ご連絡ください。
          </div>

          <ModalSubmitButtons submitText="OK" onSubmit={onClose} />
        </ModalBody>
      </ModalPortal>
    </>
  );
}
