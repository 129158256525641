// FIXME: Long lines (>1000 lines) CODE SMELLS
import React, { useState, useMemo } from "react";
import { useCurrentUser } from "../../../hooks/recoil/user";
import * as usersApi from "../../../apiClients/users";
import { CurrentUser } from "../../../apiClients/auth";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useModal } from "../../../components/Modal";
import { PageHeader } from "../../../components/Page";
import {
  useCurrentCommunityId,
  usePolyfitLocationQuery,
  usePolyfitUrl,
} from "../../../hooks/router";
import { usePTAUsers } from "../../../hooks/api/users";
import { Child } from "../../../apiClients/child";
import { useGetChildren } from "../../../hooks/api/child";
import { getStaticImageUrl } from "../../../utils/getStaticImageUrl";
import { BreadCrumb } from "../../../components/Common/BreadCrumb";
import CareerInfoContent from "../../../features/Profile/Career/CareerInfoContent";
import AppliedRecruitmentContent from "../../../features/Recruitment/application/AppliedRecruitmentContent";
import DeniedContent from "../../../features/Recruitment/application/DeniedContent";
import { zIndexes } from "../../../zIndex";
import { Avatar } from "../../../components/Common/Avatar";
import { Title } from "../../../components/Title";
import { toDisplayDateFormatYearMonth } from "../../../utils/time";
import {
  dayOfWeekOptions,
  getDayOfWeekText,
} from "../../../utils/types/dayOfWeek";
import { getGenderText } from "../../../utils/types/gender";
import { EditRoleModal } from "../../../features/Member/EditRoleModal";
import { EditBaseInfoModal } from "../../../features/Member/EditBaseInfoModal";
import { LeaveModal } from "../../../features/Member/LeaveModal";
import { useRecruitmentApplicantListByUserId } from "../../../hooks/api/recruitmentApplication";
import { getClassName } from "../../../utils/getChildClass";
import {
  isCsAdminRole,
  isPtaAdminRole,
  isAdminRole,
  roleOptions,
  isPtaMemberRole,
} from "../../../utils/types/role";
import { getAgeGroupText } from "../../../utils/types/ageGroup";
import {
  useOrganization,
  useOrganizationCommunity,
} from "../../../hooks/recoil/organization";
import { Spinner } from "../../../components/icons/Spinner";
import { usePartnerForAdmin } from "../../../hooks/api/partnerForAdmin";
import { usePartner } from "../../../hooks/api/partner";
import { useMyPostUserGroupList } from "src/hooks/query/postUserGroup";
import { useMemberUserById } from "src/hooks/query/users/useMemberUserById";
import { getCommunityUserTypeText } from "../../../features/Member/Community/utils/utils";
import {
  useFindAdditionalCommunities,
  useFindMainCommunity,
} from "src/features/Member/Community/utils/hooks";
import { Tag } from "src/components/icons/Tag";
import { useGetResidentUsers } from "src/hooks/query/residentUsers";
// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");
export const SettingButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: #e1e2e5;
  color: #343741;
  border-radius: 10px;
  border: none;
  margin-left: auto;
  padding-bottom: 10px;
  cursor: pointer;
`;

const MenuBackGround = styled.div`
  z-index: ${zIndexes.notModalMenuBackground};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

/**
 * PTA名簿のメンバー詳細ページ
 */
export default function PTAMemberPage() {
  const cu: CurrentUser = useCurrentUser();
  const { userId } = usePolyfitLocationQuery("PTA_LIST_MEMBER", { userId: "" });
  const { communityId } = useCurrentCommunityId();
  const {
    memberUser,
    getUser,
    isLoading: isMemberLoading,
    isError,
    error,
  } = useMemberUserById(userId);
  const { organization } = useOrganization({});
  const { myPostGroups } = useMyPostUserGroupList({
    accountId: memberUser?.account?.id || "",
  });
  const { children, getChildren } = useGetChildren(
    userId,
    !memberUser || isMemberLoading || memberUser.type !== "PARENT"
  );
  const { recruitmentList, isLoading, refetch } =
    useRecruitmentApplicantListByUserId(userId);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditInfoOpen, setIsEditInfoOpen] = useState(false);
  const [isEditRoleOpen, setIsEditRoleOpen] = useState(false);
  const [isLeaveOpen, setIsLeaveOpen] = useState(false);
  const isAdmin = isAdminRole(cu.role);
  const isPtaAdmin = isPtaAdminRole(cu.role);
  const isPtaMember = isPtaMemberRole(cu.role);
  const isCSAdmin = isCsAdminRole(cu.role);
  const isCurrentUser = cu.id === memberUser?.id;
  const isAdminOrCurrentUser = isAdmin || isCurrentUser;
  const [{ users }] = usePTAUsers({ skip: !isPtaAdmin });
  const { residents } = useGetResidentUsers(
    {},
    {
      enabled: !!isCSAdmin,
    }
  );
  const { partner } = usePartnerForAdmin(userId, isAdmin);
  const { partner: myPartner } = usePartner();

  const communityRoles = memberUser?.account?.communityRoles ?? [];
  const { elementarySchoolCommunities, juniorHighSchoolCommunities } =
    useOrganizationCommunity();

  const mainElementarySchool = useFindMainCommunity(
    elementarySchoolCommunities,
    communityRoles
  );
  const mainJuniorHighSchool = useFindMainCommunity(
    juniorHighSchoolCommunities,
    communityRoles
  );
  const elementarySchools = useFindAdditionalCommunities(
    elementarySchoolCommunities,
    communityRoles
  );
  const juniorHighSchools = useFindAdditionalCommunities(
    juniorHighSchoolCommunities,
    communityRoles
  );

  const isLastAdminUser = (): boolean => {
    if (!users || !memberUser) {
      // usersを読み込むまでfalse
      return false;
    }
    if (!isPtaAdminRole(memberUser.role)) {
      return false;
    }
    const adminUsers = users.filter((user) => {
      return isPtaAdminRole(user.role);
    });
    return adminUsers.length <= 1;
  };

  const isLastCSAdminUser = (): boolean => {
    if (!residents || memberUser == undefined) {
      // usersを読み込むまでfalse
      return false;
    }
    if (!isCsAdminRole(memberUser.role)) {
      return false;
    }
    const adminUsers = residents.filter((user) => {
      return isCsAdminRole(user.role);
    });
    return adminUsers.length <= 1;
  };

  const [isShown, { show, close }] = useModal();
  const [isEditInfoModal, setIsEditInfoModal] = useState(false);
  const [isEditRoleModal, setIsEditRoleModal] = useState(false);
  const [isLeaveModal, setIsLeaveModal] = useState(false);
  const backUrl = usePolyfitUrl({ to: "PTA_LIST" });

  const declineRate = useMemo(() => {
    if (!recruitmentList) {
      return 0;
    }
    const declinedApplicantList = recruitmentList.filter((recruitment) => {
      return recruitment.status === "DECLINED";
    });
    const rate = (declinedApplicantList.length / recruitmentList.length) * 100;
    return parseFloat(rate.toFixed(1));
  }, [recruitmentList]);

  if (isError) {
    //エラー処理
    if ((isCSAdmin || isPtaAdmin) && (!memberUser || memberUser.id == "")) {
      return <>指定されたユーザーは存在しません</>;
    } else if (
      error?.message == "権限がありません" ||
      error?.message == "このメンバー情報の閲覧権限がありません"
    ) {
      return <>この画面の閲覧権限がありません</>;
    } else {
      console.error(error);
      return <>エラーが発生しました</>;
    }
  } else {
    // Loading
    if (isMemberLoading || memberUser == undefined) {
      return (
        <NameListPage>
          <BreadCrumb>
            <Link
              to={{
                pathname: "/pta/list",
                search: `communityId=${communityId}`,
              }}
            >
              名簿
            </Link>
            <LinkWrap>メンバー</LinkWrap>
          </BreadCrumb>
          <NameListHeadingRow>
            <NameListHeading>
              {/* モバイル時のみ戻るボタン表示 */}
              <MobileMemberDiv>
                <PageHeader backTo={backUrl}>
                  <Title>メンバー</Title>
                </PageHeader>
              </MobileMemberDiv>
              <PCMemberDiv>
                <Title>メンバー</Title>
              </PCMemberDiv>
            </NameListHeading>
          </NameListHeadingRow>
          <MemberWrap>
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          </MemberWrap>
        </NameListPage>
      );
    } //エラーがない場合の画面
    return (
      <NameListPage>
        {isEditInfoOpen && <EditInfoModal>情報を編集</EditInfoModal>}
        {isEditRoleOpen && <EditInfoModal>権限を変更</EditInfoModal>}
        {isLeaveOpen && <EditInfoModal>退会</EditInfoModal>}
        <BreadCrumb>
          <Link
            to={{
              pathname: "/pta/list",
              search: `communityId=${communityId}`,
            }}
          >
            名簿
          </Link>
          <LinkWrap>メンバー</LinkWrap>
        </BreadCrumb>
        <NameListHeadingRow>
          <NameListHeading>
            {/* モバイル時のみ戻るボタン表示 */}
            <MobileMemberDiv>
              <PageHeader backTo={backUrl}>
                <Title>メンバー</Title>
              </PageHeader>
            </MobileMemberDiv>
            <PCMemberDiv>
              <Title>メンバー</Title>
            </PCMemberDiv>
          </NameListHeading>

          {isAdminOrCurrentUser && (
            <SettingButtonDiv>
              <div>
                <SettingButton
                  onClick={() => {
                    if (isOpen) {
                      setIsEditInfoOpen(false);
                      setIsEditRoleOpen(false);
                      setIsLeaveOpen(false);
                    }
                    setIsOpen(!isOpen);
                  }}
                >
                  …
                </SettingButton>
              </div>
              {isOpen && organization != null && (
                <>
                  <EditMenuTab>
                    <SettingEditInfo
                      onClick={() => {
                        setIsEditInfoModal(!isEditInfoModal);
                        show();
                      }}
                    >
                      情報の編集
                      {isShown && isEditInfoModal && memberUser != null && (
                        <EditBaseInfoModal
                          user={memberUser}
                          organization={organization}
                          onClose={() => {
                            setIsEditInfoModal(!isEditInfoModal);
                            close();
                          }}
                          getUser={async () => {
                            await getUser();
                          }}
                          getChildren={getChildren}
                          // childrenをpropsで渡すと怒られるのでeslint-disableを入れている
                          children={children} // eslint-disable-line
                          userId={userId}
                          cu={cu}
                        />
                      )}
                    </SettingEditInfo>
                    {(isPtaAdmin || isCSAdmin) && (
                      <SettingEditInfo
                        onClick={() => {
                          setIsEditRoleModal(!isEditRoleModal);
                          show();
                        }}
                      >
                        権限を変更
                        {isShown && isEditRoleModal && memberUser != null && (
                          <EditRoleModal
                            user={memberUser}
                            onClose={() => {
                              setIsEditRoleModal(!isEditRoleModal);
                              close();
                            }}
                            getUser={async () => {
                              await getUser();
                            }}
                            isLastAdminUser={isLastAdminUser()}
                            isLastCSAdminUser={isLastCSAdminUser()}
                          />
                        )}
                      </SettingEditInfo>
                    )}
                    {(isPtaAdmin || isCSAdmin) && memberUser.account && (
                      <SettingEditInfo
                        onClick={() => {
                          setIsLeaveModal(!isLeaveModal);
                          show();
                        }}
                      >
                        退会
                        {isShown && isLeaveModal && (
                          <LeaveModal
                            user={memberUser}
                            account={memberUser.account}
                            onClose={() => {
                              setIsLeaveModal(!isLeaveModal);
                              close();
                            }}
                            isLastAdminUser={isLastAdminUser()}
                          />
                        )}
                      </SettingEditInfo>
                    )}
                  </EditMenuTab>
                  <MenuBackGround onClick={() => setIsOpen(false)} />
                </>
              )}
            </SettingButtonDiv>
          )}
        </NameListHeadingRow>
        {/* メンバーの基本情報 */}
        <MemberWrap>
          <PreUserNameCell>
            {/* 画像 */}
            <PictureWrap>
              <Avatar
                src={memberUser?.picture || defaultUserImg}
                alt={memberUser?.name}
                size={80}
              />
              {/* 名前 */}
              <Name>
                <KanaNameDisplay>
                  {memberUser?.baseInfo?.lastNameKana}{" "}
                  {memberUser?.baseInfo?.firstNameKana}
                </KanaNameDisplay>
                <NameDisplay>
                  {memberUser?.baseInfo?.lastName}{" "}
                  {memberUser?.baseInfo?.firstName}
                </NameDisplay>
                {(memberUser.type === "PARENT" ||
                  memberUser.type === "TEACHER") && (
                  <PartAndBelongDisplay>
                    {memberUser.baseInfo?.belong?.name}{" "}
                    {memberUser.baseInfo?.part?.name}
                    {(isPtaMember || isPtaAdmin) && (
                      <OptionalWrapper>
                        {myPostGroups.map((group) => (
                          <Badge key={group.id}>
                            <Icon>
                              <Tag size={14} />
                            </Icon>
                            {group.name}
                          </Badge>
                        ))}
                      </OptionalWrapper>
                    )}
                  </PartAndBelongDisplay>
                )}
                {recruitmentList && recruitmentList.length > 0 && (
                  <DeclineRateTag>不参加率 {declineRate}％</DeclineRateTag>
                )}
              </Name>
            </PictureWrap>

            {/* 基本情報 */}
            <div style={{ padding: "15px", flex: 1 }}>
              <BaseInfoWrap>
                <BaseInfoRow>
                  {memberUser && usersApi.isResidentUser(memberUser) && (
                    <>
                      <tr>
                        <BaseInfoTh>性別</BaseInfoTh>
                        <BaseInfoTd>
                          {memberUser.baseInfo?.gender &&
                            getGenderText(memberUser.baseInfo?.gender)}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>年代</BaseInfoTh>
                        <BaseInfoTd>
                          {getAgeGroupText(memberUser.baseInfo?.ageGroup)}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>生年月日</BaseInfoTh>
                        <BaseInfoTd>
                          {memberUser.baseInfo?.birthday &&
                            toDisplayDateFormatYearMonth(
                              memberUser.baseInfo?.birthday
                            )}
                        </BaseInfoTd>
                      </tr>
                    </>
                  )}
                  {memberUser?.email && (
                    <tr>
                      <BaseInfoTh>メールアドレス</BaseInfoTh>
                      <BaseInfoTd>{memberUser?.email}</BaseInfoTd>
                    </tr>
                  )}
                  {memberUser.baseInfo?.phoneNumber && (
                    <tr>
                      <BaseInfoTh>電話番号</BaseInfoTh>
                      <BaseInfoTd>
                        {memberUser.baseInfo?.phoneNumber}
                      </BaseInfoTd>
                    </tr>
                  )}
                  {memberUser.baseInfo?.schoolGroup &&
                    memberUser.type === "PARENT" && (
                      <tr>
                        <BaseInfoTh>登校班</BaseInfoTh>
                        <BaseInfoTd>
                          {memberUser.baseInfo?.schoolGroup}
                        </BaseInfoTd>
                      </tr>
                    )}
                  {usersApi.inPTA(cu) && (
                    <tr>
                      <BaseInfoTh>ユーザータイプ</BaseInfoTh>
                      <BaseInfoTd>
                        {memberUser?.type === "PARENT"
                          ? "保護者"
                          : memberUser?.type === "TEACHER"
                          ? "教員"
                          : memberUser?.type === "RESIDENT"
                          ? "地域住民"
                          : ""}
                      </BaseInfoTd>
                    </tr>
                  )}
                  {memberUser && usersApi.isTeacherUser(memberUser) && (
                    <tr>
                      <BaseInfoTh>担任</BaseInfoTh>
                      <BaseInfoTd>
                        {memberUser.teacherInfo?.hasHomeroom
                          ? `学年 ${
                              memberUser.teacherInfo.grade
                            } / クラス ${getClassName(
                              memberUser.teacherInfo.class
                            )}`
                          : "なし"}
                      </BaseInfoTd>
                    </tr>
                  )}
                  {(usersApi.inPTA(cu) || isCSAdmin) && (
                    <tr>
                      <BaseInfoTh>権限</BaseInfoTh>
                      <BaseInfoTd>
                        {
                          roleOptions.find(
                            (role) => memberUser.role === role.value
                          )?.text
                        }
                      </BaseInfoTd>
                    </tr>
                  )}
                  {(memberUser.type === "PARENT" ||
                    memberUser.type === "TEACHER") &&
                    memberUser?.baseInfo?.selfFreeMemo && (
                      <tr>
                        <BaseInfoTh>自由記述</BaseInfoTh>
                        <BaseInfoTd>
                          {memberUser?.baseInfo?.selfFreeMemo
                            .split("\n")
                            .map((line, i) => (
                              <p key={i}>{line}</p>
                            ))}
                        </BaseInfoTd>
                      </tr>
                    )}
                  {(memberUser.type === "PARENT" ||
                    memberUser.type === "TEACHER") &&
                    isPtaAdminRole(cu.role) &&
                    memberUser?.baseInfo?.adminFreeMemo && (
                      <tr>
                        <BaseInfoTh>自由記述（役員管理用）</BaseInfoTh>
                        <BaseInfoTd>
                          {memberUser?.baseInfo?.adminFreeMemo
                            .split("\n")
                            .map((line, i) => (
                              <p key={i}>{line}</p>
                            ))}
                        </BaseInfoTd>
                      </tr>
                    )}
                  {memberUser.type === "PARENT" &&
                    children.length > 0 &&
                    children.map((child: Child, index) => {
                      return (
                        <>
                          <tr style={{ height: "16px" }}></tr>
                          <tr>
                            <BaseInfoTh>
                              お子様の名前 ({index + 1}人目)
                            </BaseInfoTh>
                            <BaseInfoTd>
                              {child.childLastName + " " + child.childFirstName}
                            </BaseInfoTd>
                          </tr>
                          <tr>
                            <BaseInfoTh>学年</BaseInfoTh>
                            <BaseInfoTd>{child.grade}</BaseInfoTd>
                          </tr>
                          <tr>
                            <BaseInfoTh>クラス</BaseInfoTh>
                            <BaseInfoTd>{getClassName(child.class)}</BaseInfoTd>
                          </tr>
                        </>
                      );
                    })}
                  {partner && (
                    <>
                      <tr>
                        <BaseInfoTh>追加保護者</BaseInfoTh>
                        <BaseInfoTd>
                          {partner?.lastName + " " + partner?.firstName}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>追加保護者のメールアドレス</BaseInfoTh>
                        <BaseInfoTd>{partner?.email}</BaseInfoTd>
                      </tr>
                    </>
                  )}
                  {!partner && myPartner && isCurrentUser && (
                    <>
                      <tr>
                        <BaseInfoTh>追加保護者</BaseInfoTh>
                        <BaseInfoTd>
                          {myPartner?.lastName + " " + myPartner?.firstName}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>追加保護者のメールアドレス</BaseInfoTh>
                        <BaseInfoTd>{myPartner?.email}</BaseInfoTd>
                      </tr>
                    </>
                  )}

                  {memberUser && usersApi.isResidentUser(memberUser) && (
                    <>
                      {isCSAdmin && (
                        <tr>
                          <BaseInfoTh>住所</BaseInfoTh>
                          <BaseInfoTd>
                            〒{memberUser?.baseInfo?.postalCode}{" "}
                            {memberUser?.baseInfo?.prefecture}
                            {memberUser?.baseInfo?.city}
                            {memberUser?.baseInfo?.address1}
                            {memberUser?.baseInfo?.address2}
                          </BaseInfoTd>
                        </tr>
                      )}
                      <tr>
                        <BaseInfoTh>希望活動曜日</BaseInfoTh>
                        <BaseInfoTd>
                          {!!memberUser.residentInfo?.preferredDays &&
                          memberUser.residentInfo?.preferredDays?.length > 0
                            ? memberUser.residentInfo?.preferredDays
                                ?.sort((a, b) => {
                                  const indexA = dayOfWeekOptions.findIndex(
                                    (dayObj) => dayObj.value === a
                                  );
                                  const indexB = dayOfWeekOptions.findIndex(
                                    (dayObj) => dayObj.value === b
                                  );
                                  return indexA - indexB;
                                })
                                .map((day) => getDayOfWeekText(day) ?? "")
                                .join("、")
                            : "該当なし"}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>スキル・資格</BaseInfoTh>
                        <BaseInfoTd>
                          {[
                            ...(memberUser.residentInfo?.teacherLicenses ?? []),
                            ...(memberUser.residentInfo?.medicalLicenses ?? []),
                            ...(memberUser.residentInfo?.skills ?? []),
                          ].join("、")}
                          {[
                            ...(memberUser.residentInfo?.teacherLicenses ?? []),
                            ...(memberUser.residentInfo?.medicalLicenses ?? []),
                            ...(memberUser.residentInfo?.skills ?? []),
                          ].length === 0 && "該当なし"}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>希望する活動内容（有償・無償）</BaseInfoTh>
                        <BaseInfoTd>
                          {memberUser.residentInfo?.preferredPaid &&
                          memberUser.residentInfo.preferredPaid.includes(
                            "PAID"
                          ) &&
                          memberUser.residentInfo.preferredPaid.includes("FREE")
                            ? "どちらでも"
                            : memberUser.residentInfo?.preferredPaid &&
                              memberUser.residentInfo.preferredPaid.includes(
                                "PAID"
                              )
                            ? "有償"
                            : memberUser.residentInfo?.preferredPaid &&
                              memberUser.residentInfo.preferredPaid.includes(
                                "FREE"
                              )
                            ? "無償"
                            : "該当なし"}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>希望ボランティア</BaseInfoTh>
                        <BaseInfoTd>
                          {memberUser.residentInfo?.preferredVolunteerType &&
                            (
                              memberUser.residentInfo.preferredVolunteerType ??
                              []
                            ).join("、")}
                          {(
                            memberUser.residentInfo?.preferredVolunteerType ??
                            []
                          ).length === 0 && "該当なし"}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>所属団体</BaseInfoTh>
                        <BaseInfoTd>
                          {[
                            ...(memberUser.residentInfo?.privateEnterprise
                              ? [memberUser.residentInfo?.privateEnterprise]
                              : []),
                            ...(memberUser.residentInfo?.university
                              ? [memberUser.residentInfo?.university]
                              : []),
                            ...(memberUser.residentInfo?.externalOrganization
                              ? [memberUser.residentInfo?.externalOrganization]
                              : []),
                            ...(memberUser.residentInfo?.pta
                              ? [memberUser.residentInfo?.pta]
                              : []),
                          ].join("、")}
                          {[
                            ...(memberUser.residentInfo?.privateEnterprise
                              ? [memberUser.residentInfo?.privateEnterprise]
                              : []),
                            ...(memberUser.residentInfo?.university
                              ? [memberUser.residentInfo?.university]
                              : []),
                            ...(memberUser.residentInfo?.externalOrganization
                              ? [memberUser.residentInfo?.externalOrganization]
                              : []),
                            ...(memberUser.residentInfo?.pta
                              ? [memberUser.residentInfo?.pta]
                              : []),
                          ].length === 0 && "該当なし"}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>最寄りの小学校区</BaseInfoTh>
                        <BaseInfoTd>
                          {mainElementarySchool?.community?.name ?? ""}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>最寄りの小学校区での役割</BaseInfoTh>
                        <BaseInfoTd>
                          {getCommunityUserTypeText(
                            mainElementarySchool?.communityRole?.type
                          )}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>最寄りの中学校区</BaseInfoTh>
                        <BaseInfoTd>
                          {mainJuniorHighSchool?.community.name ?? ""}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>最寄りの中学校区での役割</BaseInfoTh>
                        <BaseInfoTd>
                          {getCommunityUserTypeText(
                            mainJuniorHighSchool?.communityRole.type
                          )}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>追加小学校区</BaseInfoTh>
                        <BaseInfoTd>
                          {elementarySchools.length > 0 &&
                            elementarySchools
                              .map((community) => community.name)
                              .join("、")}
                        </BaseInfoTd>
                      </tr>
                      <tr>
                        <BaseInfoTh>追加中学校区</BaseInfoTh>
                        <BaseInfoTd>
                          {juniorHighSchools.length > 0 &&
                            juniorHighSchools
                              .map((community) => community.name)
                              .join("、")}
                        </BaseInfoTd>
                      </tr>
                    </>
                  )}
                </BaseInfoRow>
              </BaseInfoWrap>
            </div>
          </PreUserNameCell>
        </MemberWrap>
        {/* 過去の経歴 */}
        {usersApi.inPTA(cu) && usersApi.inPTA(memberUser) && (
          <>
            <Spacer />
            <CareerInfoContent userChildren={children} userId={memberUser.id} />
          </>
        )}
        {/* 地域住民の場合は過去の応募状態を表示 */}
        {usersApi.inCommunitySchool(cu) &&
          usersApi.inCommunitySchool(memberUser) && (
            <>
              <Spacer />
              {memberUser.id !== cu.id ? (
                <AppliedRecruitmentContent
                  isLoading={isLoading}
                  recruitmentList={recruitmentList}
                  refetch={refetch}
                />
              ) : (
                <>
                  {/* CS管理者かつ自身の名簿のときはコメントのアクセスを禁止 */}
                  <DeniedContent title="自分へのコメントは閲覧できません" />
                </>
              )}
            </>
          )}
        <Spacer />
      </NameListPage>
    );
  }
}
const EditInfoModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 50px;
  border: 1px solid;
  border-radius: 5px;
`;

const MobileMemberDiv = styled.div`
  @media (min-width: 1279px) {
    display: none;
  }
`;

const PCMemberDiv = styled.h1`
  padding-bottom: 18px;
  padding-top: 16px;
  @media (max-width: 1279px) {
    display: none;
  }
`;

const Spacer = styled.div`
  height: 20px;
`;

// FIXME: do not use "export const" with styled-components
export const SettingEditInfo = styled.div`
  font-size: 14px;
  padding: 10px 16px;
  text-align: left;
  cursor: pointer;
  &:hover {
    background-color: #e4e6f3;
  }
`;

const SettingEditTab = styled.div`
  position: absolute;
  right: 0;
  background-color: #ffffff;
  border-radius: 5px;
  width: 100%;
  border: 1px solid #e4e6f3;
  padding: 5px 0;
`;

const EditMenuTab = styled(SettingEditTab)`
  z-index: ${zIndexes.notModalMenu};
`;

const NameListPage = styled.div`
  position: relative;
`;

// FIXME: do not use "export const" with styled-components
export const NameListHeadingRow = styled.div`
  display: flex;
  padding-bottom: 5px;
`;

const NameListHeading = styled.div`
  /* font-weight: bold;
    font-size: 22px;
    color: #1A1C21; */
`;

const Name = styled.div`
  color: #343741;
  font-size: 16px;
  line-height: 24px;
  padding: 20px 40px 0 20px;
  @media (max-width: 1279px) {
    width: 100%;
    padding: 0;
    padding-left: 44px;
  }
`;

const NameDisplay = styled.div`
  font-weight: 700;
  font-size: 22px;
`;

const PreUserNameCell = styled.div`
  padding: 14px 0;
  display: grid;
  grid-template-columns: minmax(auto, 500px) 1fr;
  @media (max-width: 1000px) {
    display: block;
  }
`;

const LinkWrap = styled.span`
  font-weight: bold;
`;

const MemberWrap = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #e4e6f3;
`;

const BaseInfoWrap = styled.table`
  font-weight: normal;
  border-collapse: collapse;
  text-align: left;
`;

const BaseInfoTh = styled.th`
  padding: 5px 15px;
  color: #69707d;
  font-weight: normal;
  vertical-align: top;
  @media (max-width: 1279px) {
    min-width: 145px;
    padding: 5px 0;
    font-size: 14px;
  }
`;

const BaseInfoTd = styled.td`
  word-break: break-all;
  padding: 5px 15px;
  // border: solid 1px #a5d1f1;
  color: #343741;
  @media (max-width: 1279px) {
    padding: 5px 0;
    font-size: 14px;
  }
`;

export const PictureWrap = styled.div`
  padding: 20px;
  display: flex;
`;

const BaseInfoRow = styled.tbody``;

const SettingButtonDiv = styled.div`
  margin-left: auto;
  text-align: right;
  position: relative;
  width: 152px;
`;

const PartAndBelongDisplay = styled.div`
  margin-top: 8px;
  color: #69707d;
`;

const KanaNameDisplay = styled.div`
  font-size: 14px;
`;

const DeclineRateTag = styled.div`
  display: inline-block;
  background-color: #f1d86f;
  padding: 0 8px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 3px;
  margin-top: 8px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  padding-top: 40px;
`;

const OptionalWrapper = styled.div`
  align-items: center;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
`;

const Badge = styled.div`
  background: #f5f7fa;
  padding: 4px 8px;
  font-weight: normal;
  height: fit-content;
  font-size: 12px;
  border-radius: 5px;
  color: #343741;
  margin-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;

const Icon = styled.span`
  flex-shrink: 0;
  display: grid;
  position: center;
`;
