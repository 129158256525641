import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { Margin } from "../../../components/Margin";
import { prefectureOptions } from "../../../utils/types/prefecture";
import ResidentRecruitmentFormScheduleInput from "./RecruitmentFormScheduleInput";
import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
} from "react-hook-form";
import {
  ButtonGroupExtend,
  ButtonSelect,
} from "../../../components/ButtonSelect";
import {
  VolunteerType,
  getTitleAndDescription,
  getVolunteerDetailOption,
  volunteerOption,
} from "../../../@shared/types/volunteerType";
import {
  MedicalLicenseType,
  SkillType,
  TeachingLicenseType,
  medicalLicenseOptions,
  skillOptions,
  teachingLicenseOptions,
} from "../../../utils/types/license";
import { RecruitmentFormValue } from "./useRecruitmentForm";
import { WageType, wageTypeOptions } from "../../../utils/types/wageType";
import RecruitmentImageField from "./RecruitmentImageField";
import { Label } from "../../../components/form/Label";
import GoogleMapWithLocation from "./GoogleMapWithLocation";
import {
  QuillEditor,
  QuillEditorHandle,
} from "../../../components/form/ContentEditor";
import { CheckBox } from "../../../components/form/CheckBox";
import { useGetCommunityMemberRoleCounts } from "src/hooks/query/communityMemberRoleCounts";
import { PersonIcon } from "src/components/icons/PersonIcon";
import RecruitmentFormPDFPicker, {
  SelectedFileInfo,
} from "../../common/Recruitment/RecruitmentFormPDFPicker";
import { BREAKPOINTS } from "src/components/Responsive";

const communityUserTypeOptions: {
  value: keyof RecruitmentFormValue["recruitmentCommunityUserTypes"][string];
  text: string;
}[] = [
  {
    value: "parent",
    text: "保護者",
  },
  { value: "teacher", text: "教職員" },
  { value: "insideResident", text: "地域住民(校区内)" },
  { value: "outsideResident", text: "地域住民(校区外)" },
];

interface ResidentRecruitmentFormProps {
  control: Control<RecruitmentFormValue>;
  register: UseFormRegister<RecruitmentFormValue>;
  errors: FieldErrors<RecruitmentFormValue>;
  clearErrors: UseFormClearErrors<RecruitmentFormValue>;
  setValue: UseFormSetValue<RecruitmentFormValue>;
  trigger: UseFormTrigger<RecruitmentFormValue>;
  watchAllValue: RecruitmentFormValue;
  isSubmitted: boolean;
  getAddress: (value: string) => void;
  onPreviewClick: () => void;
  onDraftSubmit?: () => void;
  defaultPdfFiles: File[] | undefined;
  defaultAlreadyUploadedPdfFiles: { name: string; path: string }[];
}

export default function ResidentRecruitmentForm({
  watchAllValue,
  control,
  register,
  errors,
  clearErrors,
  setValue,
  trigger,
  isSubmitted,
  getAddress,
  onPreviewClick,
  onDraftSubmit,
  defaultPdfFiles,
  defaultAlreadyUploadedPdfFiles,
}: ResidentRecruitmentFormProps) {
  if (Object.keys(watchAllValue).length === 0) return <></>;

  const { communitiesMemberRoleCounts, isLoading, isError } =
    useGetCommunityMemberRoleCounts();

  const handleWageTypeChange = (value: WageType) => {
    setValue("wageType", value);
  };
  const [volunteerDetails, setVolunteerDetails] = useState<string[]>(
    watchAllValue.volunteerType
      ? getVolunteerDetailOption(watchAllValue.volunteerType).map((v) => v.text)
      : []
  );

  const quillEditorRef = useRef<QuillEditorHandle | null>(null);

  const [doubleClickBlocked, setDoubleClickBlocked] = useState(false);
  const submitDraft = async () => {
    setDoubleClickBlocked(true);
    try {
      quillEditorRef.current?.deleteImages();
      onDraftSubmit && (await onDraftSubmit());
    } finally {
      setDoubleClickBlocked(false);
    }
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const volunteerDetailType = getVolunteerDetailOption(
      event.target.value as VolunteerType
    );
    setValue("volunteerType", event.target.value as VolunteerType);
    clearErrors("volunteerType");

    if (volunteerDetailType.length > 0) {
      setVolunteerDetails(volunteerDetailType.map((v) => v.text));
      setValue("volunteerDetailType", "");
    } else {
      setVolunteerDetails([]);
    }
    insertTmpIntoTitleAndDetail(volunteerDetailType[0].volunteerType);
  };

  const handleDetailChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    insertTmpIntoTitleAndDetail(event.target.value);
    setValue("volunteerDetailType", event.target.value);
    clearErrors("volunteerDetailType");
  };

  const handleTeacherLicensesChange = (value: TeachingLicenseType) => {
    if (watchAllValue.teacherLicenses.includes(value)) {
      const newLists = watchAllValue.teacherLicenses.filter((v) => v !== value);
      setValue("teacherLicenses", newLists);
    } else {
      const newLists = [...watchAllValue.teacherLicenses, value];
      setValue("teacherLicenses", newLists);
    }
  };

  const handleMedicalLicensesChange = (value: MedicalLicenseType) => {
    if (watchAllValue.medicalLicenses.includes(value)) {
      const newLists = watchAllValue.medicalLicenses.filter((v) => v !== value);
      setValue("medicalLicenses", newLists);
    } else {
      const newLists = [...watchAllValue.medicalLicenses, value];
      setValue("medicalLicenses", newLists);
    }
  };

  const handleSkillsChange = (value: SkillType) => {
    if (watchAllValue.skills.includes(value)) {
      const newLists = watchAllValue.skills.filter((v) => v !== value);
      setValue("skills", newLists);
    } else {
      const newLists = [...watchAllValue.skills, value];
      setValue("skills", newLists);
    }
  };

  const insertTmpIntoTitleAndDetail = (detail: string) => {
    const inputType = document.getElementById(
      "volunteerType"
    ) as HTMLSelectElement;
    const inputDetail = document.getElementById(
      "volunteerDetailType"
    ) as HTMLSelectElement;

    if (inputType.value !== "" && inputDetail.value !== "") {
      const opt = volunteerOption.find(
        (option) => option.value === inputType.value
      );
      if (opt) {
        const { title, description } = getTitleAndDescription(
          opt.value,
          detail
        );
        if (title !== "" && description !== "") {
          setValue("title", title);
          clearErrors("title");
          // updateContentの中でsetValue('description', description)も実行される
          quillEditorRef.current?.updateContent(description);
        }
      }
    }
  };

  const recruitmentCommunityUserTypes =
    watchAllValue.recruitmentCommunityUserTypes;

  // 特定の学校の役割が全てチェックされているかどうか
  const allSelectedUserTypeByCommunityId = (communityId: string) => {
    if (!recruitmentCommunityUserTypes[communityId]) return false;
    const allChecked = (
      Object.keys(recruitmentCommunityUserTypes[communityId]) as Array<
        keyof (typeof recruitmentCommunityUserTypes)[string]
      >
    ).every((key) => recruitmentCommunityUserTypes[communityId][key]);
    return allChecked;
  };

  const handleSchoolChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    communityId: string
  ) => {
    const isChecked = e.target.checked;
    if (!recruitmentCommunityUserTypes[communityId]) return;
    (
      Object.keys(recruitmentCommunityUserTypes[communityId]) as Array<
        keyof (typeof recruitmentCommunityUserTypes)[string]
      >
    ).map((key) => {
      setValue(
        `recruitmentCommunityUserTypes.${communityId}.${key}`,
        isChecked
      );
    });
    if (isSubmitted) {
      await trigger();
    }
  };

  useEffect(() => {
    setValue("description", watchAllValue.description ?? "");
  }, []);

  const getCommunityUserRoleDisplayLabel = (value: number | undefined) =>
    isLoading || isError ? "-" : value ?? "-";

  const [selectedFileInfos, setSelectedFileInfos] = useState<
    SelectedFileInfo[]
  >([]);

  useEffect(() => {
    const defaultPdfInfos: SelectedFileInfo[] =
      defaultPdfFiles?.map((file) => ({
        name: file.name,
        path: URL.createObjectURL(file),
        file: file,
      })) ?? [];
    defaultAlreadyUploadedPdfFiles?.forEach((file) => {
      defaultPdfInfos?.push({
        name: file.name,
        path: file.path,
      });
    });
    if (defaultPdfInfos) {
      setSelectedFileInfos(defaultPdfInfos);
    }
  }, [defaultAlreadyUploadedPdfFiles, defaultPdfFiles]);
  const setValues = () => {
    const pdfFiles = selectedFileInfos
      .filter(
        (
          fileInfo
        ): fileInfo is {
          name: string;
          path: string;
          file: File;
        } & { file: File } => true && fileInfo.file !== undefined
      )
      .map((fileInfo) => fileInfo.file);
    setValue("pdfFiles", pdfFiles);
    const alreadyUploadedPdfFiles = selectedFileInfos
      .filter(
        (fileInfo) => fileInfo.file === undefined || !("file" in fileInfo)
      )
      .map(({ name, path }) => ({ name, path }));
    setValue("alreadyUploadedPdfFiles", alreadyUploadedPdfFiles);
  };

  return (
    <FormCardContainer>
      <FormContainer>
        <InputContainer>
          <Label size="s" optional>
            トップ画像
          </Label>
          <RecruitmentImageField
            control={control}
            register={register("uploadImage")}
            error={errors.uploadImage}
            setValue={setValue}
          />
        </InputContainer>
        <InputContainer>
          <Label size="s" required>
            活動の種類
          </Label>
          <ButtonGroupExtend>
            <ButtonSelect
              selected={watchAllValue.isPaidVolunteer === true}
              onClick={() => {
                setValue("isPaidVolunteer", true, { shouldValidate: true });
              }}
            >
              有償
            </ButtonSelect>
            <ButtonSelect
              selected={watchAllValue.isPaidVolunteer === false}
              onClick={() => {
                setValue("isPaidVolunteer", false, { shouldValidate: true });
              }}
            >
              無償
            </ButtonSelect>
          </ButtonGroupExtend>
          {errors.isPaidVolunteer && (
            <span className="error-message">
              {errors.isPaidVolunteer.message}
            </span>
          )}
          <Margin marginBottom={8} />

          <InputSelect
            id="volunteerType"
            defaultValue=""
            {...register("volunteerType")}
            onChange={handleTypeChange}
          >
            <option value="" disabled>
              種類を選択
            </option>
            {volunteerOption.map((opt) => {
              return (
                <option key={opt.value} value={opt.value}>
                  {opt.text}
                </option>
              );
            })}
          </InputSelect>
          {errors.volunteerType && (
            <span className="error-message">
              {errors.volunteerType.message}
            </span>
          )}
          <Margin marginBottom={8} />
          <InputSelect
            id="volunteerDetailType"
            {...register("volunteerDetailType")}
            defaultValue=""
            onChange={handleDetailChange}
          >
            <option value="" disabled>
              詳細を選択
            </option>
            {volunteerDetails?.map((opt) => {
              return (
                <option key={opt} value={opt}>
                  {opt}
                </option>
              );
            })}
          </InputSelect>
          {errors.volunteerDetailType && (
            <span className="error-message">
              {errors.volunteerDetailType.message}
            </span>
          )}
        </InputContainer>
        <InputContainer>
          <Label size="s" required>
            タイトル
          </Label>
          <InputText placeholder="入力してください" {...register("title")} />
          {errors.title && (
            <span className="error-message">{errors.title.message}</span>
          )}
        </InputContainer>

        <InputContainer>
          <Label size="s" required>
            活動内容
          </Label>
          <QuillEditor
            initialValue={watchAllValue.description ?? ""}
            onChange={(value) => {
              const tempDiv = document.createElement("div");
              tempDiv.innerHTML = value;
              const textValue = tempDiv.textContent ?? "";
              setValue("description", textValue, { shouldValidate: true });
            }}
            imageUploadDirectory="recruitment"
            ref={quillEditorRef}
          />
          {errors.description && (
            <span className="error-message">{errors.description.message}</span>
          )}
        </InputContainer>
        <InputContainer>
          <Label size="s" optional>
            PDFファイルを添付
          </Label>
          <RecruitmentFormPDFPicker
            selectedFileInfos={selectedFileInfos}
            setSelectedFileInfos={setSelectedFileInfos}
          />
        </InputContainer>

        <InputContainer>
          <Label size="s" required>
            募集カテゴリー選択(複数選択可)
          </Label>
          <CategoryGroup>
            {communitiesMemberRoleCounts?.map((communityMemberRoleCounts) => {
              return (
                <div key={communityMemberRoleCounts.id}>
                  <SchoolItem>
                    <CheckBox
                      label={
                        <>
                          <span>{communityMemberRoleCounts?.name ?? "-"}</span>
                          <SchoolItemUserNumber>
                            {`該当者数: ${getCommunityUserRoleDisplayLabel(
                              communityMemberRoleCounts?.counts.total
                            )}名`}
                          </SchoolItemUserNumber>
                        </>
                      }
                      checked={allSelectedUserTypeByCommunityId(
                        communityMemberRoleCounts.id
                      )}
                      onChange={(event) =>
                        handleSchoolChange(event, communityMemberRoleCounts.id)
                      }
                    />
                  </SchoolItem>
                  {communityUserTypeOptions.map((option) => {
                    return (
                      <SubCategoryItem key={option.value}>
                        <CheckBox
                          label={
                            <>
                              <span>{option.text}</span>
                              <UserNumberGroup>
                                <PersonIcon size={10} />
                                <UserNumber>
                                  {getCommunityUserRoleDisplayLabel(
                                    communityMemberRoleCounts?.counts[
                                      option.value
                                    ]
                                  )}
                                  名
                                </UserNumber>
                              </UserNumberGroup>
                            </>
                          }
                          register={register(
                            `recruitmentCommunityUserTypes.${communityMemberRoleCounts.id}.${option.value}`,
                            {
                              onChange: async () => {
                                if (isSubmitted) {
                                  await trigger();
                                }
                              },
                            }
                          )}
                          disabled={allSelectedUserTypeByCommunityId(
                            communityMemberRoleCounts.id
                          )}
                        />
                      </SubCategoryItem>
                    );
                  })}
                </div>
              );
            })}
          </CategoryGroup>
          {errors.recruitmentCommunityUserTypes && (
            <span className="error-message">
              {errors.recruitmentCommunityUserTypes.root?.message}
            </span>
          )}
        </InputContainer>

        <InputContainer>
          <Label size="s" required>
            時間・曜日
          </Label>
          <ButtonGroupExtend>
            <ButtonSelect selected={true}>単発</ButtonSelect>
            <ButtonSelect selected={false} disabled>
              長期
            </ButtonSelect>
          </ButtonGroupExtend>
          <Margin marginBottom={8} />
          <ResidentRecruitmentFormScheduleInput
            control={control}
            errors={errors}
          />
        </InputContainer>

        <InputContainer>
          <Label size="s" required disabled={!watchAllValue.isPaidVolunteer}>
            給与
          </Label>
          <ButtonGroupExtend>
            {wageTypeOptions.map((option, i) => (
              <ButtonSelect
                key={i}
                selected={watchAllValue.wageType === option.value}
                onClick={() => handleWageTypeChange(option.value)}
                disabled={!watchAllValue.isPaidVolunteer}
              >
                <span>{option.text}</span>
              </ButtonSelect>
            ))}
          </ButtonGroupExtend>
          {errors.wageType && (
            <span className="error-message">{errors.wageType.message}</span>
          )}
          <Margin marginBottom={8} />
          <WageAmountInputWrapper>
            <InputText
              placeholder="例) 10000"
              {...register("wageAmount", { valueAsNumber: true })}
              disabled={!watchAllValue.isPaidVolunteer}
              style={{ flex: 1 }}
            />
            円
          </WageAmountInputWrapper>
          {errors.wageAmount && (
            <span className="error-message">{errors.wageAmount.message}</span>
          )}
        </InputContainer>

        <InputContainer>
          <Label size="s" required>
            募集人数
          </Label>
          <VolunteerCountInputWrapper>
            <InputText
              type="number"
              placeholder="人数を選択"
              {...register("volunteerCount", { valueAsNumber: true })}
              style={{ flex: 1 }}
              min={1}
              disabled={watchAllValue.isUnlimitedVolunteerCount}
            />
            人
          </VolunteerCountInputWrapper>
          {errors.volunteerCount && (
            <span className="error-message">
              {errors.volunteerCount.message}
            </span>
          )}
          <Margin marginBottom={8} />
          <CheckBox
            label="無制限の場合はこちら"
            register={register("isUnlimitedVolunteerCount", {
              onChange: (e) => {
                if (e.target.checked) {
                  setValue("volunteerCount", undefined);
                  trigger("volunteerCount");
                }
              },
            })}
            defaultChecked={watchAllValue.isUnlimitedVolunteerCount}
          />
        </InputContainer>

        <InputContainer>
          <Label size="s" required>
            場所
          </Label>
          <AddressFields>
            <AddressFieldArea>
              <InputText
                placeholder="例）1030013"
                {...register("postalCode")}
                onChange={(e) => getAddress(e.target.value)}
              />
              {errors.postalCode && (
                <span className="error-message">
                  {errors.postalCode.message}
                </span>
              )}
              <Margin marginBottom={8} />
              <InputSelect {...register(`prefecture`)} defaultValue="">
                <option value="" disabled>
                  県名を選択
                </option>
                {prefectureOptions.map((opt) => {
                  return (
                    <option key={opt.value} value={opt.value}>
                      {opt.text}
                    </option>
                  );
                })}
              </InputSelect>
              {errors?.prefecture != null && (
                <span className="error-message">
                  {errors?.prefecture?.message}
                </span>
              )}
              <Margin marginBottom={8} />
              <InputText placeholder="例）川崎市川崎区" {...register("city")} />
              {errors.city && (
                <span className="error-message">{errors.city.message}</span>
              )}
              <Margin marginBottom={8} />

              <InputText placeholder="例）旭町3-1" {...register("address1")} />
              {errors.address1 && (
                <span className="error-message">{errors.address1.message}</span>
              )}
              <Margin marginBottom={8} />

              <InputText
                placeholder="例）〇〇マンション101号室"
                {...register("address2")}
              />
              {errors.address2 && (
                <span className="error-message">{errors.address2.message}</span>
              )}
            </AddressFieldArea>
            <GoogleMapArea>
              <GoogleMapHelpText>
                ピンをドラッグして位置を変更してください
              </GoogleMapHelpText>
              <GoogleMapWithLocationWrapper>
                <GoogleMapWithLocation
                  setValue={setValue}
                  clearErrors={clearErrors}
                  lat={watchAllValue.latitude ?? 35.6809591}
                  lng={watchAllValue.longitude ?? 139.7673068}
                />
              </GoogleMapWithLocationWrapper>
            </GoogleMapArea>
          </AddressFields>
        </InputContainer>

        <InputContainer>
          <Label size="s" optional>
            待遇
          </Label>
          <InputText
            placeholder="例）交通費全額支給"
            {...register("treatment")}
          />
          <Margin marginBottom={24} />
        </InputContainer>

        <InputContainer>
          <Label size="s" optional>
            応募資格
          </Label>
          <ButtonGroupExtend>
            {teachingLicenseOptions.map((option, i) => (
              <ButtonSelect
                key={i}
                selected={
                  watchAllValue?.teacherLicenses?.includes(option.value) ??
                  false
                }
                onClick={() => handleTeacherLicensesChange(option.value)}
              >
                <span>{option.text}</span>
              </ButtonSelect>
            ))}
            {medicalLicenseOptions.map((option, i) => (
              <ButtonSelect
                key={i}
                selected={
                  watchAllValue?.medicalLicenses?.includes(option.value) ??
                  false
                }
                onClick={() => handleMedicalLicensesChange(option.value)}
              >
                <span>{option.text}</span>
              </ButtonSelect>
            ))}
            {skillOptions.map((option, i) => (
              <ButtonSelect
                key={i}
                selected={
                  watchAllValue?.skills?.includes(option.value) ?? false
                }
                onClick={() => handleSkillsChange(option.value)}
              >
                <span>{option.text}</span>
              </ButtonSelect>
            ))}
          </ButtonGroupExtend>
        </InputContainer>

        <ButtonWrapper>
          <CheckBox
            label="この募集をひな形にする"
            register={register("saveTemplate")}
            defaultChecked={false}
          />
          <Button
            size="large"
            fill
            style={{ width: "100%" }}
            onClick={() => {
              setValues();
              onPreviewClick();
            }}
            disabled={Object.keys(errors).length > 0}
          >
            プレビューを見る
          </Button>
          {onDraftSubmit && (
            <StyledButton
              size="large"
              onClick={() => {
                setValues();
                submitDraft();
              }}
              disabled={doubleClickBlocked}
            >
              下書き保存
            </StyledButton>
          )}
        </ButtonWrapper>
      </FormContainer>
    </FormCardContainer>
  );
}

const FormCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 12px;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

const FormContainer = styled.div`
  .error-message {
    font-size: 12px;
    color: #bd271e;
  }
  max-width: 600px;
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

export const SaveTemplateWrapper = styled.div`
  text-align: center;
`;

export const TextButton = styled.div`
  color: #005ec4;
  font-size: 14px;
  cursor: pointer;
`;

const InputText = styled.input`
  width: 100%;
  color: ${(props) => (props.disabled ? "#1322951A" : "#000000")};
  background-color: #fbfcfd;
  border: 1px solid
    ${(props) => (props.disabled ? "#ddd" : "rgba(19, 34, 149, 0.1)")};
  border-radius: 6px;
  padding: 10px 8px;
  font-size: 14px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "text")};

  ::placeholder {
    color: ${(props) => (props.disabled ? "#1322951A" : "#343741")};
  }
`;

const WageAmountInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const VolunteerCountInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const InputSelect = styled.select`
  appearance: none;
  width: 100%;
  padding: 10px 40px 10px 12px;
  background-color: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  cursor: "pointer";
`;

const StyledButton = styled(Button)`
  width: 100%;
  color: #0071c2;
  background-color: #c4dcf6;
`;

const AddressFields = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: ${BREAKPOINTS.SP}) {
    flex-direction: column;
  }
`;

const AddressFieldArea = styled.div`
  flex: 1;
  min-width: 50%;
`;

const GoogleMapArea = styled.div`
  flex: 1;
  min-width: 50%;
`;

const GoogleMapHelpText = styled.p`
  color: #69707d;
  font-size: 12px;
  margin-bottom: 8px;
  /* はみ出したときの省略対応 */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const GoogleMapWithLocationWrapper = styled.div`
  height: calc(100% - 26px);
  @media (max-width: ${BREAKPOINTS.SP}) {
    height: 240px;
  }
`;

const ButtonWrapper = styled.div`
  padding-top: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`;

const CategoryGroup = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  overflow: hidden;
`;

const SchoolItem = styled.div`
  margin: 4px 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

const SchoolItemUserNumber = styled.span`
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  padding-left: 8px;
  color: #69707d;
`;

const SubCategoryItem = styled.div`
  margin-left: 28px;
  margin-bottom: 4px;
  font-size: 14px;

  &:last-child {
    border-bottom: none;
  }
`;

const UserNumberGroup = styled.span`
  padding-left: 8px;
`;

const UserNumber = styled.span`
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  padding-left: 2px;
  color: #69707d;
`;
