import React, { useEffect, useState } from "react";
import {
  useCurrentCommunityId,
  usePolyfitHistory,
} from "../../../hooks/router";
import { useCurrentUser } from "../../../hooks/recoil/user";
import styled from "styled-components";

//components
import * as Responsive from "../../../components/Responsive";
import * as Typo from "../../../components/Typo";
import { Margin } from "../../../components/Margin";
import { RegistrationFooter } from "../../../components/RegistrationFooter";
import { useOrganizationById } from "../../../hooks/api/internal/organizations";
import { SearchCommunity } from "../../../components/form/SearchCommunity";
import { useToast } from "../../../components/Toast";
import useGoogleGeocoding from "../../../hooks/useGoogleGeocoding";
import {
  getCommunityById,
  updateCommunity,
} from "../../../apiClients/community";
import { updateCommunityRoles } from "../../../apiClients/auth";

export default function RegisterOrganizationPage() {
  const history = usePolyfitHistory();
  const user = useCurrentUser();
  const toast = useToast();
  const { organization, writeOrganization } = useOrganizationById(
    user.account?.organizationId ?? ""
  );
  const { getLocationAddressByAddress } = useGoogleGeocoding();
  const { setCommunityId } = useCurrentCommunityId();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [organizationName, setOrganizationName] = useState<string>("");
  const [selectedCommunityIds, setSelectedCommunityIds] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (organization) {
      setOrganizationName(organization.name);
      const communityIds = organization.communities.map(
        (community) => community.id
      );
      setSelectedCommunityIds(communityIds);
    }
  }, [organization]);

  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!organizationName) return setErrorMessage("組織名を入力してください");
    if (selectedCommunityIds.length === 0)
      return setErrorMessage("コミュニティを選択してください");

    try {
      await Promise.all(
        selectedCommunityIds.map(async (communityId) => {
          const community = await getCommunityById(communityId);
          const res = await getLocationAddressByAddress(community.address);
          if (!res) {
            toast.error("住所が取得できませんでした");
            throw new Error("住所が取得できませんでした");
          }
          await updateCommunity({
            id: communityId,
            name: community.name,
            schoolCode: community.schoolCode,
            address: community.address,
            postalCode: community.postalCode,
            prefecture: res.prefecture,
            city: res.city,
            address1: res.address1,
            address2: res.address2,
            latitude: res.latitude,
            longitude: res.longitude,
          });
        })
      );
      // Org作成するユーザーは選択した校区のPATAdminとして登録する
      const data = selectedCommunityIds.map((communityId) => {
        return {
          roleName: "PTAAdmin",
          communityId,
          isMain: true,
          type: "PARENT",
        } as const;
      });
      await updateCommunityRoles({ userId: user.id, data, skipValidate: true });
      await writeOrganization({
        name: organizationName,
        communityIds: selectedCommunityIds,
      });

      setCommunityId(selectedCommunityIds[0]);

      history.push({
        to: "REGISTER_INFO",
      });
    } catch (error) {
      toast.error((error as any).message);
      location.reload();
    }
  };

  return (
    <div>
      <Margin marginBottom={24} />
      <form onSubmit={onSubmit}>
        <StyledContainer>
          <Margin marginBottom={16} />
          <Typo.Heading3>組織名の入力</Typo.Heading3>
          <Responsive.Row>
            <Responsive.Col>
              <Margin marginBottom={8} />
              <Typo.Heading4>組織名</Typo.Heading4>
              <Margin marginBottom={4} />
              <TextField
                onChange={(e) => setOrganizationName(e.target.value)}
                type="text"
                value={organizationName}
                placeholder={"組織名を入力"}
              />
              <Margin marginBottom={12} />
              <Typo.Heading4>コミュニティを選択</Typo.Heading4>
              <SearchCommunity
                serviceType="PTA"
                selectedCommunityIds={selectedCommunityIds}
                setSelectedCommunityIds={setSelectedCommunityIds}
              />

              <span className="error-message">{errorMessage}</span>
            </Responsive.Col>
          </Responsive.Row>
        </StyledContainer>
        <RegistrationFooter isDisplayBeforeButton={false} />
      </form>
    </div>
  );
}

const StyledContainer = styled.div`
  max-width: 496px;
  margin: 0 auto;
  .error-message {
    font-size: 12px;
    color: #bd271e;
  }
  @media (max-width: 1279px) {
    max-width: 343px;
  }
`;

const TextField = styled.input`
  width: 100%;
  padding: 10px 12px;
  background: #fbfcfd;
  border: 1px rgba(19, 34, 149, 0.1) solid;
  border-radius: 6px;
  font-size: 14px;
  color: #343741;
  line-height: 21px;
  ::placeholder {
    color: #aab4c4;
  }
`;
