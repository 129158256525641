import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import ExternalLinkIcon from "src/components/icons/ExternalLinkIcon";
import { createPolyfitUrl } from "src/hooks/router";
import { useMessageList } from "../useMessageList";
import { ChatMessage } from "src/features/common/ChatMessage/components/ChatMessage";
import { DateLabel } from "src/features/common/ChatMessage/components/DateLabel";
import { BREAKPOINTS } from "src/components/Responsive";
import { ChatRoomBackButton } from "src/features/common/ChatMessage/components/ChatRoomBackButton";
import { SystemMessage } from "src/features/common/ChatMessage/components//SystemMessage";
import { useFloatingDateLabel } from "src/features/common/ChatMessage/hooks/useFloatingDateLabel";
import { useToast } from "src/components/Toast";
import { updateParticipantChatRoom } from "src/apiClients/participantChatRoom";
import { createChatMessage } from "src/apiClients/chatMessage";
import { APIError } from "src/utils/types/ApiError";
import { ChatInputForm } from "src/components/form/ChatInputForm";
import { useCurrentUser } from "src/hooks/recoil/user";
import { updateAdminOnly } from "src/apiClients/chatRoom";
import { ChatRoom as ChatRoomType } from "@shared/types/chatRoom";
import { ChatMessages } from "src/features/common/ChatMessage/components/ChatMessages";

type Props = {
  isOpen: boolean;
  chatRoom: ChatRoomType;
  chatRoomParticipantId: string;
  lastReadAt: Date;
  recruitmentId: string;
  recruitmentDuration: string | null;
  recruitmentTitle: string;
  isDeletedRecruitment: boolean;
};

export const ChatRoomRecruitmentBoardEducationContent = (props: Props) => {
  const chatRoomInfoRef = useRef<HTMLDivElement>(null);
  const [input, setInput] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [sending, setSending] = useState(false);
  const messageListRef = useRef<HTMLDivElement>(null); // チャットメッセージエリアの参照
  const toast = useToast();
  const user = useCurrentUser();
  const isAdmin = props.chatRoomParticipantId !== user.id;
  const { displayItemList, lastReadAt, NewMessageNotification } =
    useMessageList({
      initLastReadAt: props.lastReadAt,
      messageListRef,
      chatRoomParticipantId: props.chatRoomParticipantId,
    });
  const currentDate = useFloatingDateLabel({
    messageListRef,
    displayItemList,
  }); // メッセージ一覧画面の上部にfixedで表示する日付

  // チャットルームの最終アクセス日時を更新
  useEffect(() => {
    const updateParticipantChatRoomHandler = async () => {
      if (!props.chatRoom.id) return;
      await updateParticipantChatRoom({
        chatRoomId: props.chatRoom.id,
      });
    };

    updateParticipantChatRoomHandler();

    const interval = setInterval(updateParticipantChatRoomHandler, 10000);

    return () => {
      clearInterval(interval);
      updateParticipantChatRoomHandler();
    };
  }, [props.chatRoom.id]);

  // ChatRoomInfoの高さ分paddingをつける
  const chatRoomInfoHeight = useMemo(() => {
    return chatRoomInfoRef.current?.clientHeight ?? 0;
  }, [chatRoomInfoRef.current]);

  const applicationDetailUrl = useMemo(() => {
    return createPolyfitUrl({
      to: "RESIDENT_RECRUITMENT_DETAIL",
      query: { id: props.recruitmentId },
    });
  }, [props.recruitmentId]);

  const onSend = async () => {
    if (!canSend) return;
    setSending(true);
    try {
      await createChatMessage(props.chatRoom.id, input, files);
      setInput("");
    } catch (e) {
      if (e instanceof APIError) {
        toast.error(e.message);
        return;
      }
      toast.error("メッセージの送信に失敗しました");
    } finally {
      setSending(false);
    }
  };

  const canSend = useMemo(() => {
    return !sending && (input.length > 0 || files.length > 0);
  }, [sending, input, files]);

  const onCheckedAdminOnly = async (checked: boolean) => {
    await updateAdminOnly(props.chatRoom.id, checked);
  };

  if (!displayItemList) return <></>;

  return (
    <ChatRoom isOpen={props.isOpen}>
      <ChatRoomInfo ref={chatRoomInfoRef}>
        <RecruitmentTitle>
          <ChatRoomBackButton targetRoute="RESIDENT_CHAT" />
          {props.recruitmentTitle}
          {!props.isDeletedRecruitment && (
            <LinkWrapper>
              <a href={applicationDetailUrl} target="_blank" rel="noreferrer">
                <ExternalLinkIcon />
              </a>
            </LinkWrapper>
          )}
        </RecruitmentTitle>
        <HeaderSubLabel>{props.recruitmentDuration}</HeaderSubLabel>
      </ChatRoomInfo>
      <ChatBox paddingTop={chatRoomInfoHeight}>
        <ChatMessages messageListRef={messageListRef}>
          {currentDate && <FloatDateLabel>{currentDate}</FloatDateLabel>}
          {displayItemList.map((item, index) => {
            switch (item.itemType) {
              case "dateLabel":
                return (
                  <DateLabel key={index} data={item} className="date-label" />
                );
              case "userMessage":
                return (
                  <ChatMessage
                    key={index}
                    message={item}
                    participantId={props.chatRoomParticipantId}
                    lastReadAt={lastReadAt}
                  />
                );
              case "systemMessage":
                return <SystemMessage key={index} message={item} />;
            }
          })}
        </ChatMessages>
        <NewMessageNotification />
        {!props.isDeletedRecruitment && (
          <ChatMessageInputArea>
            <ChatInputForm
              isAdmin={isAdmin}
              adminOnly={props.chatRoom.adminOnly}
              onCheckedAdminOnly={onCheckedAdminOnly}
              placeholder="メッセージを入力"
              setValue={setInput}
              setFiles={setFiles}
              canSend={canSend}
              onSend={onSend}
            />
          </ChatMessageInputArea>
        )}
      </ChatBox>
    </ChatRoom>
  );
};

const LinkWrapper = styled.div`
  display: flex;
  width: 72px;
  height: 27px;
  padding: 1px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  gap: 4px;
  text-align: center;
  line-height: 18px; /* 150% */
  margin-left: auto;
  cursor: pointer;
`;

const ChatRoom = styled.div<{
  isOpen: boolean;
}>`
  position: relative;
  flex: 1;
  min-width: 0;
  height: 100%;
  max-height: 100%;
  background: #fff;
  @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
    display: ${(props) => (props.isOpen ? "block" : "none")};
  }
`;

const ChatRoomInfo = styled.div`
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ChatBox = styled.div<{ paddingTop: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${(props) =>
    props.paddingTop ? props.paddingTop + "px 8px 0 8px" : "60px 8px 0 8px"};
  height: 100%;
`;

const ChatMessageInputArea = styled.div`
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RecruitmentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #313131;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  gap: 8px;
  & > button {
    display: none;
    @media (max-width: ${BREAKPOINTS.CHAT_MAX}) {
      display: block;
    }
  }
`;

const HeaderSubLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #69707d;
`;

const FloatDateLabel = styled.div`
  background: white;
  height: 24px;
  font-size: 12px;
  padding: 0 16px;
  border-radius: 24px;
  border: 1px solid rgba(221, 221, 221, 1);
  text-align: center;
  width: fit-content;
  font-weight: 600;
  position: sticky;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  line-height: 22px;
`;
