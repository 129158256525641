import React, { useMemo } from "react";
import styled from "styled-components";
import RecruitmentThumbnailImage from "./RecruitmentThumbnailImage";
import { WalkingIcon } from "../../../../components/icons/WalkingIcon";
import { BikeIcon } from "../../../../components/icons/BikeIcon";
import { CarIcon } from "../../../../components/icons/CarIcon";
import {
  toDateIntervalFormat,
  toHourMinuteFormat,
} from "../../../../utils/time";
import { getWageTypeText } from "../../../../utils/types/wageType";
import { BREAKPOINTS } from "../../../../components/Responsive";
import HtmlViewer from "../../../../features/Post/HTMLViewer";
import GoogleMapArea from "../../../../components/GoogleMapArea";
import ExternalLinkIcon from "../../../../components/icons/ExternalLinkIcon";
import { Avatar } from "../../../../components/Common/Avatar";
import { getStaticImageUrl } from "../../../../utils/getStaticImageUrl";
import { useCurrentUser } from "../../../../hooks/recoil/user";
import { BERecruitment } from "@shared/types/boardEducation/recruitment";
import { BERecruitmentTemplate } from "@shared/types/boardEducation/recruitmentTemplate";
import { useGetManagedOrganization } from "src/hooks/query/boardEducation/organizations/organization";
import { RecruitmentOrigin } from "@shared/types/recruitment";
import { useGetDownloadUrls } from "src/hooks/query/firebaseStorage";
import { PdfIcon } from "src/components/icons/PdfIcon";
import { SelectedFileInfo } from "src/features/common/Recruitment/RecruitmentFormPDFPicker";
// publicディレクト配下の絶対パスを指定
const defaultUserImg = getStaticImageUrl("/defaultUserImg.png");

type RecruitmentDetailCardProps = {
  recruitment: BERecruitment;
  recruitmentPreview?: BERecruitment | BERecruitmentTemplate | null;
  isRecruitmentCreateNew?: boolean;
  selectedFileInfos?: SelectedFileInfo[];
};

export const RecruitmentDetailCard = ({
  recruitment,
  recruitmentPreview,
  isRecruitmentCreateNew = false,
  selectedFileInfos = undefined,
}: RecruitmentDetailCardProps) => {
  const user = useCurrentUser();
  const { organization, isLoading } = useGetManagedOrganization();

  const createdNameBy = useMemo(() => {
    return {
      title:
        recruitment.origin === RecruitmentOrigin.CS ? "校区" : "教育委員会",
      value:
        recruitment.origin === RecruitmentOrigin.CS
          ? recruitment.community?.name
          : organization?.name,
    };
  }, [organization]);

  const { urls } = useGetDownloadUrls(
    recruitment.recruitmentPDFs?.map((fileInfo) => fileInfo.path) || []
  );

  const getTargetCommunity = (communityId: string) => {
    return recruitment.targetCommunities.find(
      (target) => target.communityId === communityId
    );
  };

  if (isLoading) return <></>;

  return (
    <Card>
      <RecruitmentThumbnailImage
        src={recruitment.picture}
        title={recruitment.title}
      />
      <CardBody>
        <TagWrapper>
          <Tag color="blue">{recruitment.volunteerType}</Tag>

          <Tag color="blue">{recruitment.volunteerDetailType}</Tag>

          <Tag color="green">
            {recruitment.isPaidVolunteer ? "有償" : "無償"}
          </Tag>

          <Tag color="pink">単発</Tag>

          {recruitment.walkMinute !== undefined &&
            recruitment.walkMinute !== null &&
            recruitment.bikeMinute !== undefined &&
            recruitment.bikeMinute !== null &&
            recruitment.carMinute !== undefined &&
            recruitment.carMinute !== null && (
              <>
                <Tag color="orange">
                  <WalkingIcon />
                  {toHourMinuteFormat(recruitment.walkMinute)}
                </Tag>
                <Tag color="orange">
                  <BikeIcon />
                  {toHourMinuteFormat(recruitment.bikeMinute)}
                </Tag>
                <Tag color="orange">
                  <CarIcon />
                  {toHourMinuteFormat(recruitment.carMinute)}
                </Tag>
              </>
            )}
        </TagWrapper>

        <ContentsWrapper>
          <Title>{recruitment.title}</Title>
          {/* 新規募集作成のプレビュー */}
          {isRecruitmentCreateNew ? (
            <>
              <UserPicCell>
                <PreUserPicCell>
                  <Avatar
                    src={user?.picture || defaultUserImg}
                    alt={user?.name || "作成者"}
                    size={30}
                  />
                </PreUserPicCell>
                <PreUserNameCell>
                  作成者
                  <Name>{user.name}</Name>
                </PreUserNameCell>
              </UserPicCell>

              <UserPicCell>
                <PreUserPicCell>
                  <HiddenAvater>
                    <Avatar
                      src={defaultUserImg}
                      alt={organization?.name || "教育委員会"}
                      size={30}
                    />
                  </HiddenAvater>
                </PreUserPicCell>
                <PreUserNameCell>
                  {createdNameBy.title}
                  <Name>{createdNameBy.value}</Name>
                </PreUserNameCell>
              </UserPicCell>
            </>
          ) : (
            <>
              <UserPicCell>
                <PreUserPicCell>
                  <Avatar
                    src={recruitment.account?.user.picture || defaultUserImg}
                    alt={recruitment.account?.user.name || "作成者"}
                    size={30}
                  />
                </PreUserPicCell>
                <PreUserNameCell>
                  作成者
                  <Name>{recruitment.account?.user.name}</Name>
                </PreUserNameCell>
              </UserPicCell>

              <UserPicCell>
                <PreUserPicCell>
                  <HiddenAvater>
                    <Avatar
                      src={defaultUserImg}
                      alt={recruitment.community?.name || "校区"}
                      size={30}
                    />
                  </HiddenAvater>
                </PreUserPicCell>
                <PreUserNameCell>
                  {createdNameBy.title}
                  <Name>{createdNameBy.value}</Name>
                </PreUserNameCell>
              </UserPicCell>
            </>
          )}
          <Content>
            <SubTitle>活動内容</SubTitle>
            <Text>
              <HtmlViewer htmlContent={recruitment.description} />
            </Text>
          </Content>
          {recruitment.recruitmentPDFs &&
            recruitment.recruitmentPDFs.length > 0 && (
              <Content>
                <SubTitle>添付資料</SubTitle>
                <PdfFiles>
                  {recruitment.recruitmentPDFs.map((fileInfo, index) => {
                    return (
                      <div key={index}>
                        <PdfPreview>
                          <PdfIconWrapper>
                            <PdfIcon size={24} />
                          </PdfIconWrapper>
                          <PdfLabel target="blank" href={urls[index]}>
                            {fileInfo.name}
                          </PdfLabel>
                        </PdfPreview>
                      </div>
                    );
                  })}
                </PdfFiles>
              </Content>
            )}
          {/* 作成時のプレビュー用 */}
          {selectedFileInfos && selectedFileInfos.length > 0 && (
            <Content>
              <SubTitle>添付資料</SubTitle>
              <PdfFiles>
                {selectedFileInfos.map((fileInfo, index) => {
                  return (
                    <div key={index}>
                      <PdfPreview>
                        <PdfIconWrapper>
                          <PdfIcon size={24} />
                        </PdfIconWrapper>
                        <PdfLabel target="blank" href={fileInfo.path}>
                          {fileInfo.name}
                        </PdfLabel>
                      </PdfPreview>
                    </div>
                  );
                })}
              </PdfFiles>
            </Content>
          )}
          <Content>
            <SubTitle>募集カテゴリー</SubTitle>
            <Text>
              {organization?.communities.map((community) => {
                return (
                  <Ul key={community.id}>
                    {getTargetCommunity(community.id) ? (
                      <>
                        <li>{community.name}</li>
                        <Ul>
                          {getTargetCommunity(
                            community.id
                          )?.communityUserTypes.includes("PARENT") && (
                            <li>保護者</li>
                          )}
                          {getTargetCommunity(
                            community.id
                          )?.communityUserTypes.includes("TEACHER") && (
                            <li>教職員</li>
                          )}
                          {getTargetCommunity(
                            community.id
                          )?.communityUserTypes.includes("INSIDE_RESIDENT") && (
                            <li>地域住民（校区内）</li>
                          )}
                          {getTargetCommunity(
                            community.id
                          )?.communityUserTypes.includes(
                            "OUTSIDE_RESIDENT"
                          ) && <li>地域住民（校区外）</li>}
                          {getTargetCommunity(
                            community.id
                          )?.communityUserTypes.includes("ADMIN") && (
                            <li>管理者</li>
                          )}
                        </Ul>
                      </>
                    ) : (
                      <></>
                    )}
                  </Ul>
                );
              })}
            </Text>
          </Content>

          <Content>
            <SubTitle>場所</SubTitle>
            <a
              href={`https://www.google.com/maps?q=${recruitment.latitude},${recruitment.longitude}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Text>
                〒{recruitment.postalCode?.slice(0, 3)}-
                {recruitment.postalCode?.slice(3)}
              </Text>
              <Address>
                {recruitment.prefecture}
                {recruitment.city}
                {recruitment.address1}
                {recruitment.address2}
                <ExternalLinkIconWrapper>
                  <ExternalLinkIcon size={12} />
                </ExternalLinkIconWrapper>
              </Address>
            </a>
            <GoogleMapWithLocationWrapper>
              <GoogleMapArea
                lat={recruitment.latitude ?? 35.6809591}
                lng={recruitment.longitude ?? 139.7673068}
              />
            </GoogleMapWithLocationWrapper>
          </Content>

          <Content>
            <SubTitle>時間・曜日</SubTitle>
            <div>
              {recruitment.schedule.map((schedule, i) => (
                <Text key={i}>
                  {toDateIntervalFormat(
                    new Date(schedule.start),
                    new Date(schedule.end)
                  )}
                </Text>
              ))}
            </div>
          </Content>

          {recruitment.isPaidVolunteer && (
            <Content>
              <SubTitle>給与</SubTitle>
              <Text>
                {`${getWageTypeText(recruitment.wageType)}${
                  recruitment.wageAmount
                }円`}
              </Text>
            </Content>
          )}

          {recruitment.volunteerCount && (
            <Content>
              <SubTitle>募集人数</SubTitle>
              <Text>
                {recruitment.volunteerCount === -1
                  ? "無制限"
                  : `${recruitment.volunteerCount}人`}
              </Text>
            </Content>
          )}

          <Content>
            <SubTitle>待遇</SubTitle>
            <Text>{recruitment.treatment}</Text>
          </Content>

          <Content>
            <SubTitle>応募資格</SubTitle>
            <Text>
              <Ul>
                {[
                  ...recruitment.teacherLicenses,
                  ...recruitment.medicalLicenses,
                  ...recruitment.skills,
                ].map((license, i) => (
                  <li key={i}>{license}</li>
                ))}
              </Ul>
            </Text>
            {[
              ...recruitment.teacherLicenses,
              ...recruitment.medicalLicenses,
              ...recruitment.skills,
            ].length === 0 && <Text>なし</Text>}
          </Content>
        </ContentsWrapper>
      </CardBody>
    </Card>
  );
};

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

const CardBody = styled.div`
  padding: 24px;
  @media (max-width: ${BREAKPOINTS.TABLET}) {
    padding: 12px;
  }
`;

const TagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  column-gap: 8px;
  margin-bottom: 12px;
`;

const Tag = styled.p<{
  color: "blue" | "green" | "pink" | "orange";
}>`
  background-color: ${(props) => {
    switch (props.color) {
      case "blue":
        return "#79aad9";
      case "green":
        return "#6DCCB1";
      case "pink":
        return "#EE789D";
      case "orange":
        return "#FF7E62";
      default:
        return "#79aad9";
    }
  }};
  padding: 4px 12px;
  border-radius: 16px;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  width: fit-content;
`;

const Title = styled.p`
  color: #343741;
  font-size: 24px;
  font-weight: bold;
  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 16px;
  }
`;

const ContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: ${BREAKPOINTS.SP}) {
    gap: 12px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: ${BREAKPOINTS.SP}) {
    gap: 2px;
  }
`;

const SubTitle = styled.p`
  color: #343741;
  font-size: 20px;
  font-weight: bold;
  @media (max-width: ${BREAKPOINTS.SP}) {
    font-size: 12px;
  }
`;

const Text = styled.p`
  color: #343741;
  white-space: pre-wrap;
  font-size: 14px;
`;

const Ul = styled.ul`
  padding-left: 18px;
`;

const GoogleMapWithLocationWrapper = styled.div`
  width: 100%;
  height: 320px;
`;

const Address = styled(Text)`
  position: relative;
`;

const ExternalLinkIconWrapper = styled.span`
  position: relative;
  & > svg {
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
  }
`;

const UserPicCell = styled.th`
  color: #343741;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const Name = styled.div`
  color: #343741;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  text-align: left;
`;

const HiddenAvater = styled.div`
  visibility: hidden;
`;

const PreUserPicCell = styled.div`
  padding: 10px;
`;

const PreUserNameCell = styled.div`
  text-align: left;
`;

const PdfFiles = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 8px;
`;
const PdfLabel = styled.a`
  font-size: 14px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &:hover {
    text-decoration: underline;
  }
`;

const PdfIconWrapper = styled.div`
  flex-shrink: 0;
`;
const PdfPreview = styled.div`
  max-width: 208px;
  display: flex;
  align-items: center;
  padding: 4px;
  gap: 2px;
  border-radius: 8px;
  border: 1px solid #ececec;
  background-color: #ffffff;
  margin-top: 4px;
  &:last-child {
    margin-right: 0;
  }
`;
