import * as client from "./client";
import { AppliedRecruitment } from "@shared/types/appliedRecruitment";
import { RecruitmentStatus } from "@shared/types/recruitment";
import { RecruitmentApplicationListOrder } from "@shared/types/recruitmentApplication";

/**
 * 応募系APIクライアント 取得系
 */
export type RecruitmentApplication = {
  id: string;
  accountId: string;
  user: {
    id: string;
    email: string;
    name: string;
    picture: string | null;
  };
  coverLetterChatMessage?: {
    content: string;
  };
  status: "APPLIED" | "APPROVED" | "REJECTED" | "DECLINED";
  rejectedAt: Date;
  recruitment: {
    id: string;
    picture: string | null;
    title: string | null;
    status: RecruitmentStatus;
    createdBy?: string;
    schedule: {
      start: Date;
      end: Date;
    }[];
  };
};

export const applicationStatusOptions = [
  { value: "APPLIED", text: "選考中" },
  { value: "APPROVED", text: "参加決定" },
  { value: "REJECTED", text: "見送り" },
  { value: "DECLINED", text: "不参加" },
] as const;

export type ApplicationStatus =
  (typeof applicationStatusOptions)[number]["value"];

export async function getMyApplicationByRecruitmentId(
  recruitmentId: string
): Promise<RecruitmentApplication> {
  return await client.get<{}, RecruitmentApplication>(
    "/recruitment-application/getByRecruitmentId",
    {
      recruitmentId,
    }
  );
}

export async function getApplicationListByStatus(
  status: ApplicationStatus | "ALL",
  name: string,
  order?: RecruitmentApplicationListOrder
): Promise<RecruitmentApplication[]> {
  return await client.get<
    {
      status: ApplicationStatus | "ALL";
      name: string;
      order?: RecruitmentApplicationListOrder;
    },
    RecruitmentApplication[]
  >("/recruitment-application/getListByStatus", { status, name, order });
}

export async function getApplicationListByUserId(
  userId: string
): Promise<AppliedRecruitment[]> {
  return await client.get<{}, AppliedRecruitment[]>(
    "/recruitment-application/getListByUserId",
    {
      userId,
    }
  );
}

export async function getApplicationListByFilter(
  status: ApplicationStatus | "ALL",
  name: string,
  recruitmentId: string | undefined
): Promise<RecruitmentApplication[]> {
  return await client.get<
    {
      status: ApplicationStatus | "ALL";
      name: string;
      recruitmentId: string | undefined;
    },
    RecruitmentApplication[]
  >("/recruitment-application/getListByFilter", {
    status,
    name,
    recruitmentId,
  });
}

export async function downloadApplicationListPdfByFilter(
  status: ApplicationStatus | "ALL",
  name: string,
  recruitmentId: string | undefined
): Promise<Blob> {
  return await client.getBlob<
    {
      status: ApplicationStatus | "ALL";
      name: string;
      recruitmentId: string | undefined;
    },
    Blob
  >("/recruitment-application/downloadListPdfByFilter", {
    status,
    name,
    recruitmentId,
  });
}

export type CountApplicationResponse = {
  count: number;
};
export async function countApplication(): Promise<CountApplicationResponse> {
  return await client.get<{}, CountApplicationResponse>(
    "/recruitment-application/count",
    {}
  );
}
/**
 * 応募系APIクライアント 更新系
 */
export async function approveApplication(
  recruitApplicationId: string
): Promise<void> {
  await client.post<{ recruitApplicationId: string }, { result: string }>(
    "/recruitment-application/approve",
    { recruitApplicationId }
  );
}

export async function rejectApplication(
  recruitApplicationId: string,
  comment: string
): Promise<void> {
  await client.post<
    { recruitApplicationId: string; comment: string },
    { result: string }
  >("/recruitment-application/reject", { recruitApplicationId, comment });
}

export async function declineApplicationByRecruitmentApplicationId(
  recruitApplicationId: string,
  comment: string
): Promise<void> {
  await client.post<
    { recruitApplicationId: string; comment: string },
    { result: string }
  >("/recruitment-application/declineByRecruitmentApplicationId", {
    recruitApplicationId,
    comment,
  });
}

export async function declineApplicationByRecruitmentId(
  recruitmentId: string,
  comment: string
): Promise<void> {
  await client.post<
    { recruitmentId: string; comment: string },
    { result: string }
  >("/recruitment-application/declineByRecruitmentId", {
    recruitmentId,
    comment,
  });
}
