import { useSearchParams } from "react-router-dom";
import { logout } from "../utils/auth";
import { auth } from "../utils/firebase";
import { APIError } from "../utils/types/ApiError";
import { useMemo } from "react";
import { useFirebaseUserContext } from "src/hooks/context/firebaseUser";

type EmptyObject = Record<string, never>;
export const PATH_PREFIX =
  import.meta.env.VITE_SERVER_URL ?? `${location.origin}/api`;

export async function getToken(): Promise<string> {
  const user = auth.currentUser;
  const idToken = (await user?.getIdToken()) ?? "";
  return idToken;
}

export async function refreshToken() {
  const user = auth.currentUser;
  await user?.getIdToken(true);
}

export async function get<
  Query extends { [key: string]: string | number | undefined } = EmptyObject,
  Response = EmptyObject
>(path: string, query: Query): Promise<Response> {
  const searchParams = new URLSearchParams(location.search);
  const currentCommunityId = searchParams.get("communityId");
  const url = new URL(PATH_PREFIX + path);
  Object.entries({
    ...query,
  })
    .filter(([, value]) => value !== undefined)
    .forEach(([key, value]) => {
      if (typeof value === "number") {
        url.searchParams.append(key, value.toString());
      } else if (typeof value === "string") {
        url.searchParams.append(key, value);
      }
    });
  if (currentCommunityId) {
    url.searchParams.append("currentCommunityId", currentCommunityId);
  }
  const idToken = await getToken();
  const res = await fetch(url.toString(), {
    method: "GET",
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });
  if (res.status === 401) {
    await logout();
  }
  const json = await res.json();
  if (!res.ok) {
    throw new APIError(json);
  }
  return json;
}

export async function getBlob<
  Query extends { [key: string]: string | undefined } = EmptyObject,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Response = EmptyObject
>(path: string, query: Query) {
  const searchParams = new URLSearchParams(location.search);
  const currentCommunityId = searchParams.get("communityId");
  const url = new URL(PATH_PREFIX + path);
  Object.entries({
    ...query,
  }).forEach(([key, value]) => {
    if (value !== undefined) {
      url.searchParams.append(key, value);
    }
  });
  if (currentCommunityId) {
    url.searchParams.append("currentCommunityId", currentCommunityId);
  }
  const idToken = await getToken();
  const res = await fetch(url.toString(), {
    method: "GET",
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });
  if (!res.ok) {
    const json = await res.json();
    throw new APIError(json);
  }
  return await res.blob();
}

export async function post<Body = EmptyObject, Response = EmptyObject>(
  path: string,
  body: Body = {} as Body
): Promise<Response> {
  const searchParams = new URLSearchParams(location.search);
  const currentCommunityId = searchParams.get("communityId");
  const url = new URL(PATH_PREFIX + path);
  if (currentCommunityId) {
    url.searchParams.append("currentCommunityId", currentCommunityId);
  }
  const idToken = await getToken();
  const res = await fetch(url.toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(body),
  });
  if (res.status === 401) {
    await logout();
  }
  const json = await res.json();
  if (!res.ok) {
    throw new APIError(json);
  }
  return json;
}

type FormDataBody = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};
export async function postWithFormData<
  Body extends FormDataBody = EmptyObject,
  Response = EmptyObject
>(path: string, body: Body): Promise<Response> {
  const searchParams = new URLSearchParams(location.search);
  const currentCommunityId = searchParams.get("communityId");
  const url = new URL(PATH_PREFIX + path);
  if (currentCommunityId) {
    url.searchParams.append("currentCommunityId", currentCommunityId);
  }
  const formData = new FormData();
  Object.entries(body).forEach(([key, value]) => {
    if (Array.isArray(value) && value.every((v) => v instanceof File)) {
      value.forEach((v: File) => {
        formData.append(key, v, encodeURIComponent(v.name));
      });
    } else {
      formData.append(key, value);
    }
  });
  const idToken = await getToken();
  const res = await fetch(url.toString(), {
    method: "POST",
    body: formData,
    headers: {
      authorization: `Bearer ${idToken}`,
    },
  });
  const json = await res.json();
  if (!res.ok) {
    throw new APIError(json);
  }
  return json;
}

export function useApiContext() {
  const [searchParams] = useSearchParams();
  const communityId = searchParams.get("communityId");

  const { idTokenClaims } = useFirebaseUserContext();

  const apiContext = useMemo(() => {
    return {
      communityId,
      accountId: idTokenClaims?.currentAccountId,
    };
  }, [communityId, idTokenClaims]);

  return { apiContext };
}
